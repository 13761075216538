import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableContainer,
  Typography,
} from "@mui/material";
import { Trans } from "react-i18next";

import { CobaltIcon } from "@docaposte-agility/da-design-system/";

import {
  FluxTableData,
  FluxTableHeadCell,
  FluxTableOrder,
  FluxPageRowsPageList,
} from "types/FluxTable.d";
import { FluxStatus } from "enums/FluxStatus.d";
import { PhidContext } from "utils/Contexts";

// =============================================================================
// EnhancedTableHead...
// =============================================================================
interface EnhancedTableProps {
  headCells: FluxTableHeadCell[];
  order: FluxTableOrder;
  orderBy: string;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof FluxTableData,
  ) => void;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const { headCells, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof FluxTableData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  // Enhanced Table Head. ------------------------------------------------------
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// =============================================================================
// FluxTable...
// =============================================================================
interface FluxTableProp {
  rowData: FluxTableData[];
  headCells: FluxTableHeadCell[];
  totalRows: number;
  onAction: (action: string, value: string) => void;
}
const FluxTable: React.FC<FluxTableProp> = ({
  rowData,
  headCells,
  totalRows,
  onAction,
}) => {
  // Members. ------------------------------------------------------------------
  const { offsetGeneral, countGeneral, setCountGeneral } =
    React.useContext(PhidContext);
  const [order, setOrder] = React.useState<FluxTableOrder>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof FluxTableData>("noSort");
  const [page, setPage] = React.useState(offsetGeneral / countGeneral);
  const [rowsPerPage, setRowsPerPage] = React.useState(countGeneral);

  React.useEffect(() => {
    if (offsetGeneral === 0) {
      setPage(0);
    }
  }, [offsetGeneral]);

  // handles row. --------------------------------------------------------------
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    onAction("Select", id);
  };

  // Handle paginator. ---------------------------------------------------------
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    onAction("ChangePage", "" + newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    onAction("ChangeRowsPerPage", "" + event.target.value);
    setCountGeneral(parseInt(event.target.value, 10));
  };

  // Handle header. ------------------------------------------------------------
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof FluxTableData,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Sorting. ------------------------------------------------------------------
  function getComparator<Key extends keyof any>(
    order: FluxTableOrder,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  // Cell Column format. -------------------------------------------------------
  const TableCellStatus = (labelId, col) => {
    if (Object.values(FluxStatus).indexOf(col) !== -1) {
      return (
        <TableCell
          id={labelId}
          scope="row"
          padding="none"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          <Typography
            variant="subtitle1"
            color={
              col === FluxStatus.ERREUR
                ? "cobalt.redText"
                : col === FluxStatus.ANNULE
                ? "cobalt.lynch"
                : "cobalt.turquoiseIcon"
            }
          >
            <CobaltIcon
              name={
                col === FluxStatus.ERREUR
                  ? "alert-fill"
                  : col === FluxStatus.ANNULE
                  ? "bullet-point"
                  : "check"
              }
              sx={{
                width: "24px",
                pr: "5px",
                verticalAlign: "middle",
              }}
            />
            <Trans>{"flux." + col}</Trans>
          </Typography>
        </TableCell>
      );
    } else {
      return (
        <TableCell
          id={labelId}
          scope="row"
          padding="none"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        ></TableCell>
      );
    }
  };
  const TableCellOthers = (col) => {
    return (
      <TableCell
        style={{
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
      >
        <Typography variant="body2" color="cobalt.lynch">
          {col}
        </Typography>
      </TableCell>
    );
  };

  // Phid Flux table render. ---------------------------------------------------
  return (
    <Paper sx={{ p: "0px", m: "0px" }}>
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table stickyHeader sx={{ tableLayout: "fixed" }}>
          {/* Header. ------------------------------------------------------ */}
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />

          {/* Body. -------------------------------------------------------- */}
          <TableBody>
            {stableSort(rowData, getComparator(order, orderBy)).map(
              (row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    {TableCellStatus(labelId, row.col1)}
                    {TableCellOthers(row.col2)}
                    {TableCellOthers(row.col3)}
                    {TableCellOthers(row.col4)}
                    {TableCellOthers(row.col5)}
                    {TableCellOthers(row.col6)}
                    {TableCellOthers(row.col7)}
                    {TableCellOthers(row.col8)}
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginator. ------------------------------------------------------- */}
      {totalRows !== 0 ? (
        <TablePagination
          rowsPerPageOptions={FluxPageRowsPageList}
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "cobalt.lynch",
            p: 4,
          }}
        >
          Pas de données à afficher
        </Typography>
      )}
    </Paper>
  );
};
export default FluxTable;
