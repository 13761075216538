import React from "react";
import { Trans } from "react-i18next";
import { Grid, Snackbar, Typography } from "@mui/material";
import { Alert } from "@docaposte-agility/da-design-system";

import LoginForm from "app/components/login/LoginForm";
import LoginReset from "app/components/login/LoginReset";

// =============================================================================
// Login...
// =============================================================================
const Login = () => {
  // Members. ------------------------------------------------------------------
  const [loginMode, setLoginMode] = React.useState(true);
  const [toasterMsg, setToasterMsg] = React.useState("");
  const [toasterType, setToasterType] = React.useState("");
  const [actionDisabled, setActionDisabled] = React.useState(false);

  // handleAlertStatus. ------------------------------------------------------
  const handleAlertStatus = (type: string, message: string) => {
    setLoginMode(true);
    if (type !== "cancel") {
      setToasterType(type);
      setToasterMsg(message);
      setActionDisabled(true);
    }
  };

  // Automatic Toaster close. --------------------------------------------------
  React.useEffect(() => {
    if (toasterType !== "") {
      setTimeout(() => {
        setToasterType("");
        setActionDisabled(false);
      }, 3000);
    }
  }, [toasterType]);

  // login page render. --------------------------------------------------------
  return (
    <>
      {/* Alert toaster success. ------------------------------------------- */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toasterType === "success"}
        sx={{ minWidth: "100%" }}
        message={
          <Alert
            elevation={3}
            onClose={() => {
              setActionDisabled(false);
              setToasterType("");
            }}
          >
            {toasterMsg}
          </Alert>
        }
      />

      {/* Alert toaster Error. --------------------------------------------- */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toasterType === "error"}
        sx={{ minWidth: "100%" }}
        message={
          <Alert
            elevation={3}
            onClose={() => {
              setActionDisabled(false);
              setToasterType("");
            }}
            severity="error"
            style={{ background: "#FEEFF4" }} // cobalt.mandyBackground
          >
            {toasterMsg}
          </Alert>
        }
      />

      {/* Main. ------------------------------------------------------------ */}
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{
          minHeight: "100vh",
          minWidth: "100vw",
          background: `url("/images/background.png") center center no-repeat fixed`,
          backgroundSize: "cover",
          margin: 0,
          padding: 0,
          outline: 0,
        }}
      >
        {/* Body. ------------------------------------------------------------ */}
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="stretch"
          xs
        >
          {/* Fast description. ---------------------------------------------- */}
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            xs
          >
            <Typography
              variant="h3"
              sx={{ m: "40px", p: "0px", color: "cobalt.ultramarine" }}
            >
              <Trans>common.shortDesciption</Trans>
            </Typography>
          </Grid>

          {/* Action: Login / passwrod forget. ------------------------------- */}
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            xs="auto"
          >
            {loginMode ? (
              <LoginForm
                onResetPassword={() => setLoginMode(false)}
                onLoginStatus={handleAlertStatus}
                onDisabled={actionDisabled}
              ></LoginForm>
            ) : (
              <LoginReset onResetStatus={handleAlertStatus}></LoginReset>
            )}
          </Grid>
        </Grid>

        {/* Logo. ------------------------------------------------------------ */}
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          sx={{ height: "60px", pr: "20px" }}
        >
          <img
            src="/images/docaposte.png"
            style={{
              border: "none",
              height: "50px",
              pointerEvents: "none",
              cursor: "pointer",
            }}
            alt=""
          />
        </Grid>
      </Grid>
    </>
  );
};
export default Login;
