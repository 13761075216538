import React from "react";
import { Trans } from "react-i18next";
import { Grid, Paper, Typography } from "@mui/material";

// =============================================================================
// FluxDetailBox1: display flux detail on box size 1/3.
// =============================================================================
interface FluxDetailBox1Prop {
  id: string;
  value: string;
}
const FluxDetailBox1: React.FC<FluxDetailBox1Prop> = ({ id, value }) => {
  return (
    <Grid item xs={6} sm={4} sx={{ p: "5px", m: "0px" }}>
      <Paper
        elevation={2}
        sx={{
          height: "100%",
          border: "1px solid",
          borderColor: "cobalt.grey10",
          borderRadius: "8px",
          p: "20px",
        }}
      >
        {/* id: icon + label. -------------------------------------------- */}
        <Typography variant="subtitle1" sx={{ color: "cobalt.ultramarine" }}>
          <img
            src={"/images/icons/" + id + ".svg"}
            style={{
              height: "18px",
              marginRight: "10px",
              verticalAlign: "middle",
            }}
            alt=""
          />
          <Trans>{"fluxDetail." + id}</Trans>
        </Typography>

        {/* Valeur. ------------------------------------------------------ */}
        <Grid container wrap="nowrap">
          <Grid
            item
            xs
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            <Typography
              variant="body1"
              sx={{ mt: "10px", color: "cobalt.lynch" }}
            >
              {value || ". . ."}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default FluxDetailBox1;
