import React from "react";
import { useNavigate } from "react-router-dom";

import NavDrawer from "app/components/NavDrawer";
import AccountsList from "app/components/accounts/AccountsList";

import { PhidContext } from "utils/Contexts";
import { getStorageValue, setStorageValue } from "utils/MemoryStorage";

import { AccountsType } from "enums/AccountType.d";

// =============================================================================
// Clients...
// =============================================================================
const Clients = () => {
  // Members. ------------------------------------------------------------------
  const { navRoutes } = React.useContext(PhidContext);
  const [accessAllowed, setAccessAllowed] = React.useState(false);
  let navigate = useNavigate();

  // Check access. -------------------------------------------------------------
  React.useEffect(() => {
    if (getStorageValue("role")) {
      if (Object.keys(navRoutes).length !== 0) {
        if (Object.keys(navRoutes).indexOf("clients") !== -1) {
          setStorageValue("route", "/phid/clients");
          setAccessAllowed(true);
        } else {
          navigate("/phid/notFound", { replace: true });
        }
      }
    } else {
      navigate("/phid", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navRoutes]);

  // Client render. -------------------------------------------------------------
  return (
    <>
      {accessAllowed && (
        <NavDrawer mode="clients">
          {/* Tableau Client. --------------------------------------------------- */}
          <AccountsList type={AccountsType.CLIENTS} />
        </NavDrawer>
      )}
    </>
  );
};
export default Clients;
