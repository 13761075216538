import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { theme } from "@docaposte-agility/da-design-system";
import {
  executeRequestInterceptor,
  executeResponseInterceptor,
} from "config/axios";
import CssBaseline from "@mui/material/CssBaseline";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      retry: (failureCount, error: any) => {
        if (failureCount > 2 || error?.response?.status === 404) {
          return false;
        }
        return true;
      },
    },
  },
});

const Providers = ({ children }) => {
  useEffect(() => {
    executeRequestInterceptor();
    executeResponseInterceptor();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};

export default Providers;
