import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Login from "app/pages/Login";
import Dashboard from "app/pages/Dashboard";
import Flux from "app/pages/Flux";
import Clients from "app/pages/Clients";
import Users from "app/pages/Users";
import NotFound from "app/pages/NotFound";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/phid" />} />
      <Route path="/phid" element={<Login />} />
      <Route path="/phid/dashboard" element={<Dashboard />} />
      <Route path="/phid/flux" element={<Flux />} />
      <Route path="/phid/flux/:id" element={<Flux />} />
      <Route path="/phid/clients" element={<Clients />} />
      <Route path="/phid/users" element={<Users />} />
      <Route path="/phid/notFound" element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
