import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// GetVersion...
// =============================================================================
export const GetVersion = (
  config: UseMutationOptions<{ id_token: string }, AxiosError, {}> = {},
) => {
  // eslint-disable-next-line no-empty-pattern
  return useMutation(({}) => Axios.get("/version", { params: {} }), {
    ...config,
  });
};
