import React from "react";

import Providers from "app/Providers";
import Contexts from "utils/Contexts";
import { BrowserRouter } from "react-router-dom";

import "config/i18n"; // react i18n multilanguage.

import Router from "app/Router";

function App() {
  return (
    <Providers>
      <Contexts>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Contexts>
    </Providers>
  );
}

export default App;
