import React from "react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import i18next from "i18next";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { CobaltIcon } from "@docaposte-agility/da-design-system";

import { PhidContext } from "utils/Contexts";
import { setStorageValue } from "utils/MemoryStorage";

import { UserRoles } from "enums/UserRoles";

// =============================================================================
// NavDrawer...
// =============================================================================
interface NavDrawerProp {
  mode: string;
  children: React.ReactNode;
}
const NavDrawer: React.FC<NavDrawerProp> = ({ mode, children }) => {
  // Members. ------------------------------------------------------------------
  const {
    backdrop,
    toasterSuccess,
    toasterError,
    toasterWarning,
    navRoutes,
    drawer,
    setContextValue,
  } = React.useContext(PhidContext);
  const exitDesc = i18next.t("drawer.exit");

  // Navigation. ---------------------------------------------------------------
  let navigate = useNavigate();

  // handlers. -----------------------------------------------------------------
  const handleLink = (path: string) => {
    navigate(path);
  };
  const handleExit = () => {
    setStorageValue("role");
    setStorageValue("id");
    setStorageValue("token");
    setContextValue("userRoutes", UserRoles.USER);
    navigate("/phid");
  };

  // Automatic close of Toaster. -----------------------------------------------
  React.useEffect(() => {
    if (toasterSuccess !== "" || toasterError !== "" || toasterWarning !== "") {
      setTimeout(() => {
        setContextValue("toasterSuccess", "");
        setContextValue("toasterError", "");
        setContextValue("toasterWarning", "");
      }, 3000);
    }
  }, [setContextValue, toasterSuccess, toasterError, toasterWarning]);

  // NavDrawer render. ---------------------------------------------------------
  return (
    <Grid
      container
      direction={{ lg: "column", sm: "row" }}
      justifyContent="flex-start"
      alignItems="stretch"
      style={{
        height: "100vh",
        width: "100%",
        overflow: "none",
      }}
    >
      {/* Vertical Nav. ---------------------------------------------------- */}
      <Grid
        item
        sx={{
          width: drawer ? "250px" : "60px",
          backgroundColor: "cobalt.white",
          borderRight: "solid 1px",
          borderColor: "cobalt.grey10",
        }}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
      >
        {/* Nav Title. ----------------------------------------------------- */}
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="stretch"
          sx={{ p: "5px" }}
        >
          <Grid
            item
            sx={{
              p: "5px 5px 5px 16px",
              m: 0,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              borderRadius: "28px",
              height: "28px",
            }}
          >
            <IconButton
              sx={{ p: 0, m: 0, cursor: "pointer" }}
              data-testid="nav-drawer-expand"
              onClick={() => setContextValue("drawer")}
            >
              <img
                src="/images/icons/phid-logo.svg"
                style={{
                  border: "none",
                  width: "24px",
                  pointerEvents: "none",
                  cursor: "pointer",
                }}
                alt=""
              />
            </IconButton>
            {drawer && (
              <Typography
                variant="h3"
                sx={{ pl: "12px", color: "cobalt.bleuDigital100" }}
              >
                <Trans>common.produit</Trans>
              </Typography>
            )}
          </Grid>
        </Grid>

        {/* Divider. ------------------------------------------------------- */}
        <Divider
          sx={{
            ml: "5px",
            width: drawer ? "240px" : "50px",
            backgroundColor: "cobalt.grey10",
          }}
        />

        {/* Nav Link. ------------------------------------------------------ */}
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          sx={{ pb: "12px", pl: "5px", pr: "5px" }}
          xs
        >
          {Object.keys(navRoutes).map((name, index) => (
            <Grid
              key={index}
              item
              sx={{
                p: "5px 5px 5px 16px",
                mt: "10px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                borderRadius: "28px",
                cursor: "pointer",
                backgroundColor:
                  name === mode ? "cobalt.bleuDigital10" : "cobalt.white",
              }}
              data-testid={"nav-drawer-link-" + name}
              onClick={() => handleLink("/phid/" + name)}
            >
              <Tooltip
                title={drawer ? "" : navRoutes[name][1]}
                placement="right"
                arrow
              >
                <CobaltIcon
                  name={navRoutes[name][0]}
                  sx={{
                    p: 0,
                    m: 0,
                    width: "24px",
                    color: "cobalt.ultramarine",
                  }}
                />
              </Tooltip>
              {drawer && (
                <Typography
                  variant="h6"
                  sx={{ pl: "8px", color: "cobalt.ultramarine" }}
                >
                  {navRoutes[name][1]}
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>

        {/* Divider. ------------------------------------------------------- */}
        <Divider
          sx={{
            ml: "5px",
            width: drawer ? "240px" : "50px",
            backgroundColor: "cobalt.grey10",
          }}
        />

        {/* Nav Exit. ------------------------------------------------------ */}
        <Grid
          item
          container
          direction="column"
          justifyContent="flex-end"
          alignItems="stretch"
          sx={{ p: "5px" }}
        >
          <Grid
            item
            sx={{
              p: "5px 5px 5px 16px",
              m: 0,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              borderRadius: "28px",
              cursor: "pointer",
              height: "28px",
            }}
            data-testid="nav-drawer-exit"
            onClick={handleExit}
          >
            <Tooltip title={drawer ? "" : exitDesc} placement="right" arrow>
              <CobaltIcon
                name="log-out"
                sx={{
                  p: 0,
                  m: 0,
                  width: "24px",
                  color: "cobalt.ultramarine",
                }}
              />
            </Tooltip>
            {drawer && (
              <Typography
                variant="h6"
                sx={{ pl: "8px", color: "cobalt.ultramarine" }}
              >
                <Trans>drawer.exit</Trans>
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>

      {/* Body. ------------------------------------------------------------ */}
      <Grid
        item
        xs
        sx={{
          backgroundColor: "cobalt.light",
        }}
      >
        {/* Success toaster. ----------------------------------------------- */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toasterSuccess !== ""}
          sx={{ minWidth: "100%" }}
          message={
            <Alert
              elevation={3}
              onClose={() => {
                setContextValue("toasterSuccess", "");
              }}
            >
              {toasterSuccess}
            </Alert>
          }
        />

        {/* Error toaster. ------------------------------------------------- */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toasterError !== ""}
          sx={{ minWidth: "100%" }}
          message={
            <Alert
              elevation={3}
              onClose={() => {
                setContextValue("toasterError", "");
              }}
              severity="error"
              style={{ background: "#FEF4F5" }} // cobalt.redBackground
            >
              {toasterError}
            </Alert>
          }
        />

        {/* Warning toaster. ----------------------------------------------- */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={toasterWarning !== ""}
          sx={{ minWidth: "100%" }}
          message={
            <Alert
              elevation={3}
              onClose={() => {
                setContextValue("toasterWarning", "");
              }}
              severity="warning"
              style={{ background: "#FFF4EC" }} // cobalt.orangeBackground
            >
              {toasterWarning}
            </Alert>
          }
        />

        {/* Backdrop: actif during backend call. --------------------------- */}
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdrop}
        >
          <CircularProgress color="inherit" size="200px" />
        </Backdrop>

        {/* Display children. ---------------------------------------------- */}
        <Grid
          sx={{ p: "0px 24px 24px 24px" }}
          style={{
            height: "100vh",
            overflow: "auto",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default NavDrawer;
