import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// DeleteClient...
// =============================================================================
export const DeleteClient = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    { id: string }
  > = {},
) => {
  return useMutation(
    ({ id }) => Axios.delete("/client/" + id, { params: {} }),
    {
      ...config,
    },
  );
};
