import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { PhidContext } from "utils/Contexts";
import { getStorageValue, setStorageValue } from "utils/MemoryStorage";

import NavDrawer from "app/components/NavDrawer";
import FluxList from "app/components/flux/FluxList";
import FluxDetail from "app/components/flux/FluxDetail";
import FluxFilter from "app/components/flux/FluxFilter";

// =============================================================================
// Flux...
// =============================================================================
const Flux = () => {
  // Members. ------------------------------------------------------------------
  const { navRoutes } = React.useContext(PhidContext);
  const [accessAllowed, setAccessAllowed] = React.useState(false);
  let { id } = useParams();
  let navigate = useNavigate();

  // Check access. -------------------------------------------------------------
  React.useEffect(() => {
    if (getStorageValue("role")) {
      if (Object.keys(navRoutes).length !== 0) {
        if (Object.keys(navRoutes).indexOf("flux") !== -1) {
          setStorageValue("route", "/phid/flux");
          setAccessAllowed(true);
        } else {
          navigate("/phid/notFound", { replace: true });
        }
      }
    } else {
      navigate("/phid", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navRoutes]);

  // Flux render. --------------------------------------------------------------
  return (
    <>
      {accessAllowed && (
        <NavDrawer mode="flux">
          {/* Flux list array. ------------------------------------------------- */}
          {!id && (
            <Grid sx={{ p: "0px 24px 24px 24px" }}>
              {/* Title. ------------------------------------------------------- */}
              <Typography
                variant="h1"
                sx={{
                  m: "24px 0px",
                  color: "cobalt.ultramarine",
                  textAlign: "left",
                }}
              >
                <Trans>flux.title</Trans>
              </Typography>

              {/* Filter. ------------------------------------------------------ */}
              <FluxFilter />

              {/* Tableau flux. --------------------------------------------------- */}
              <FluxList />
            </Grid>
          )}

          {/* Flux view detail. ------------------------------------------------ */}
          {id && <FluxDetail id={id} />}
        </NavDrawer>
      )}
    </>
  );
};
export default Flux;
