import React from "react";
import { Grid, Paper, Typography } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

// =============================================================================
// FluxBoxKpi...
// =============================================================================
interface FluxBoxKpiProp {
  type: string;
  title: string;
  value: string;
}
const FluxBoxKpi: React.FC<FluxBoxKpiProp> = ({ type, title, value }) => {
  // Members. ------------------------------------------------------------------
  const icons = {
    kpiError: "alert-fill",
    kpiInProgress: "activity",
    kpiFinished: "check-fill",
  };

  // Pki box render. -----------------------------------------------------------
  return (
    <Paper
      elevation={2}
      sx={{
        width: "356px",
        height: "104px",
        p: "20px 24px 20px 24px",
        m: "12px 0px 12px 24px",
        border: "1px solid",
        borderColor: "cobalt.grey10",
        borderRadius: "8px",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{ height: "100%" }}
      >
        {/* Title. --------------------------------------------------------- */}
        <Grid item>
          <Typography
            variant="h4"
            sx={{ pb: "12px", color: "cobalt.ultramarine" }}
          >
            {title}
          </Typography>
        </Grid>

        {/* Value. --------------------------------------------------------- */}
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <CobaltIcon
            name={icons[type]}
            sx={{
              width: "24px",
              color:
                type === "kpiError"
                  ? "cobalt.mandyText"
                  : type === "kpiInProgress"
                  ? "cobalt.dodgerBlueText"
                  : "cobalt.turquoiseIcon",
            }}
          />
          <Typography
            variant="h1"
            sx={{
              color:
                type === "kpiError"
                  ? "cobalt.mandyText"
                  : type === "kpiInProgress"
                  ? "cobalt.dodgerBlueText"
                  : "cobalt.turquoiseIcon",
            }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default FluxBoxKpi;
