import React from "react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";

import { getStorageValue } from "utils/MemoryStorage";

// =============================================================================
// NotFound...
// =============================================================================
const NotFound = () => {
  // Members. ------------------------------------------------------------------
  let navigate = useNavigate();

  // Check access. -------------------------------------------------------------
  React.useEffect(() => {
    if (!getStorageValue("role")) {
      navigate("/phid", { replace: true });
    }
  });

  // Handlers. -----------------------------------------------------------------
  const goBack = () => {
    if (getStorageValue("role")) {
      navigate(getStorageValue("route") || "/phid", { replace: true });
    }
  };

  // NotFound render. ----------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        minHeight: "100vh",
        minWidth: "100vw",
        background: `url("/images/background.png") center center no-repeat fixed`,
        backgroundSize: "cover",
        margin: 0,
        padding: 0,
        outline: 0,
      }}
    >
      {/* Message box. ----------------------------------------------------- */}
      <Paper
        elevation={3}
        sx={{
          width: "500px",
          height: "250px",
          flex: "0 auto",
          p: "30px",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          sx={{ height: "100%" }}
        >
          {/* Title. ------------------------------------------------------- */}
          <Grid item>
            <Typography variant="h1" sx={{ pb: "15px", textAlign: "center" }}>
              <Trans>common.erreur404</Trans>
            </Typography>
            <Divider />
          </Grid>

          {/* Body. -------------------------------------------------------- */}
          <Grid item>
            <Typography variant="h2" sx={{ textAlign: "center" }}>
              <Trans>common.message404</Trans>
            </Typography>
          </Grid>

          {/* Action. ------------------------------------------------------ */}
          <Grid item container justifyContent="center" alignItems="center">
            <Button
              data-testid="notFound-goBack"
              onClick={goBack}
              sx={{ textAlign: "center" }}
            >
              <Trans>common.goBack</Trans>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default NotFound;
