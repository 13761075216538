import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// PostAuthenticate...
// =============================================================================
export const PostAuthenticate = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    { login: string; password: string }
  > = {},
) => {
  return useMutation(
    ({ login, password }) =>
      Axios.post("/authenticate", null, { params: { login, password } }),
    {
      ...config,
    },
  );
};
