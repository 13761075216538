import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// import i18next from 'i18next';
// i18next.changeLanguage('fr');

import fr from "languages/fr.json";

const resources = { fr };

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next).use(LanguageDetector).init({
  resources,
  defaultNS: "phid",
  fallbackLng: "fr",
});
