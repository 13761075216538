const DateNow = (extra?: string) => {
  // ouuput date format (ISOString): "AAAA-MM-JJTHH:MM:SS.sssZ"
  const date = new Date().toISOString();
  if (extra === "FirstDayOfMonth") {
    return date.slice(0, 8) + "01" + date.slice(10);
  } else {
    return date;
  }
};
export default DateNow;
