import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// GetFluxList...
// =============================================================================
export const GetFluxList = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    {
      client?: string;
      status?: string;
      flux?: string;
      startDate?: string;
      endDate?: string;
      offset: number;
      count: number;
    }
  > = {},
) => {
  return useMutation(
    ({ client, status, flux, startDate, endDate, offset, count }) =>
      Axios.get("/flux", {
        params: { client, status, flux, startDate, endDate, offset, count },
      }),
    {
      ...config,
    },
  );
};
