import React from "react";
import { Box, TextField as MuiTextField, Typography } from "@mui/material";

interface EmailFieldProps {
  label: string;
  [x: string]: any;
}

const EmailField = (props: EmailFieldProps) => {
  const { label = "", ...other } = props;

  return (
    <MuiTextField
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography variant="subtitle2">{label}</Typography>
        </Box>
      }
      type="email"
      fullWidth
      placeholder={label}
      {...other}
    />
  );
};
export default EmailField;
