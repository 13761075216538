import React from "react";
import i18next from "i18next";

import { PhidContext } from "utils/Contexts";
import ClientEdit from "app/components/accounts/ClientEdit";
import UserEdit from "app/components/accounts/UserEdit";

import DateFormat from "services/date/DateFormat";

import { Account } from "types/Account.d";
import { AccountsTableData } from "types/AccountsTable.d";
import { ClientForm } from "types/ClientForm.d";
import { UserForm } from "types/UserForm.d";
import { EditMode } from "enums/EditMode.d";
import { AccountsType } from "enums/AccountType.d";

// let selectFiltered: string[] = [];
// let rowFiltered: AccountsTableData[] = [];
// let nbDeleted: number;

// =============================================================================
// AccountsEdit...
// =============================================================================
interface AccountsEditProp {
  type: AccountsType;
  mode: EditMode;
  clientForm: ClientForm;
  userForm: UserForm;
  selected: string[];
  rowData: AccountsTableData[];
  onEditResults: (action: EditMode, account: Account) => void;
}
const AccountsEdit: React.FC<AccountsEditProp> = ({
  type,
  mode,
  clientForm,
  userForm,
  selected,
  rowData,
  onEditResults,
}) => {
  // Members. ------------------------------------------------------------------
  const { setContextValue } = React.useContext(PhidContext);

  // handleEditAction. ---------------------------------------------------------
  const handleEditAction = (
    mode: EditMode,
    status: string,
    account?: Account,
  ) => {
    // Create. -----------------------------------------------------------------
    if (mode === EditMode.CREATE) {
      if (status === "OK") {
        if (type === AccountsType.CLIENTS) {
          rowData.push({
            id: account?.client?._key || "",
            col1: account?.client?.libelle || "",
            col2: DateFormat(
              account?.client?.created_at || "",
              i18next.t("common.date"),
            ),
            col3: JSON.stringify(account?.client?.idsClient || []),
            extra: "",
          });
        } else {
          rowData.push({
            id: account?.user?._key || "",
            col1: account?.user?.login || "",
            col2: DateFormat(
              account?.user?.created_at || "",
              i18next.t("common.date"),
            ),
            col3: i18next.t("userRole." + account?.user?.role),
            extra: account?.user?.password || "",
          });
        }
        setContextValue(
          "toasterSuccess",
          String(i18next.t(type + ".CreateSuccess")),
        );
      } else {
        setContextValue(
          "toasterError",
          String(i18next.t(type + ".CreateError")),
        );
      }
    }

    // Update. -----------------------------------------------------------------
    else if (mode === EditMode.UPDATE) {
      const foundUpdate = rowData.findIndex((elem) => {
        if (type === AccountsType.CLIENTS) {
          return elem.id === account?.client?._key;
        } else {
          return elem.id === account?.user?._key;
        }
      });
      if (foundUpdate >= 0) {
        if (status === "OK") {
          if (type === AccountsType.CLIENTS) {
            rowData[foundUpdate] = {
              id: account?.client?._key || "",
              col1: account?.client?.libelle || "",
              col2: DateFormat(
                account?.client?.created_at || "",
                i18next.t("common.date"),
              ),
              col3: JSON.stringify(account?.client?.idsClient || []),
              extra: "",
            };
          } else {
            rowData[foundUpdate] = {
              id: account?.user?._key || "",
              col1: account?.user?.login || "",
              col2: DateFormat(
                account?.user?.created_at || "",
                i18next.t("common.date"),
              ),
              col3: i18next.t("userRole." + account?.user?.role),
              extra: account?.user?.password || "",
            };
          }
          setContextValue(
            "toasterSuccess",
            String(i18next.t(type + ".UpdateSuccess")),
          );
        } else {
          setContextValue(
            "toasterError",
            String(i18next.t(type + ".UpdateError")),
          );
        }
      }
    }

    // Delete. -----------------------------------------------------------------
    else if (mode === EditMode.DELETE || mode === EditMode.DELETESELECTED) {
      const select = account?.selected || [];
      if (status === "OK") {
        if (select.length === 1) {
          setContextValue(
            "toasterSuccess",
            String(i18next.t(type + ".DeleteSuccessOne")),
          );
        } else {
          setContextValue(
            "toasterSuccess",
            String(
              i18next
                .t(type + ".DeleteSuccessSeveral")
                .replace("__NB__", "" + select.length),
            ),
          );
        }
      } else if (status === "ERROR") {
        if (selected.length < 2 || mode === EditMode.DELETE) {
          setContextValue(
            "toasterError",
            String(i18next.t(type + ".DeleteErrorOne")),
          );
        } else {
          setContextValue(
            "toasterError",
            String(
              i18next
                .t(type + ".DeleteErrorSeveral")
                .replace("__NB__", "" + select.length + "/" + selected.length),
            ),
          );
        }
      } else {
        setContextValue(
          "toasterWarning",
          String(i18next.t(type + ".DeleteWarning")),
        );
      }
      onEditResults(mode, account || {});
    }

    setContextValue("backdrop", false);
    mode = EditMode.NONE;
  };

  // Account edit render. ------------------------------------------------------
  return (
    <>
      {mode !== "" && (
        <>
          {type === AccountsType.CLIENTS ? (
            <ClientEdit
              mode={mode}
              clientForm={clientForm}
              selected={selected}
              onEditAction={handleEditAction}
            />
          ) : (
            <UserEdit
              mode={mode}
              userForm={userForm}
              selected={selected}
              onEditAction={handleEditAction}
            />
          )}
        </>
      )}
    </>
  );
};
export default AccountsEdit;
