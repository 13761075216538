import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// PostResetPassword...
// =============================================================================
export const PostResetPassword = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    { mail: string }
  > = {},
) => {
  return useMutation(
    ({ mail }) =>
      Axios.post("/authenticate/reset-password", null, { params: { mail } }),
    {
      ...config,
    },
  );
};
