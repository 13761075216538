import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// GetFluxKpi...
// =============================================================================
export const GetFluxKpi = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    { start: string; end: string }
  > = {},
) => {
  return useMutation(
    ({ start, end }) => Axios.get("/flux/kpi", { params: { start, end } }),
    {
      ...config,
    },
  );
};
