import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

import Field from "app/components/formField/Field";
import TextField from "app/components/formField/TextField";

import { PhidContext } from "utils/Contexts";

import { CobaltIcon } from "@docaposte-agility/da-design-system/";
import { t } from "i18next";
import { getStorageValue, setStorageValue } from "utils/MemoryStorage";

import { SearchClientForm } from "types/SearchClientForm";

// =============================================================================
// FluxFilter...
// =============================================================================
interface ClientFilterProp {}
const ClientFilter: React.FC<ClientFilterProp> = () => {
  // Members. ------------------------------------------------------------------
  const { setContextValue } = React.useContext(PhidContext);
  const [filterValues] = React.useState(
    JSON.parse(getStorageValue("clientFilter") || "{}"),
  );
  // Form control. -------------------------------------------------------------
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<SearchClientForm>({
    defaultValues: {
      libelle: filterValues?.libelle,
      idClient: filterValues?.idClient,
    },
  });

  // handleSearchReset. --------------------------------------------------------
  const handleSearchReset = () => {
    setValue("libelle", "");
    setValue("idClient", "");
    setContextValue("clientFilter", "{}");
    setStorageValue("clientFilter", "{}");
  };

  // handleSearch. -------------------------------------------------------------
  const handleSearch = (searchForm: SearchClientForm) => {
    let filter = {};
    if (searchForm.libelle !== "" && searchForm.libelle !== undefined) {
      filter["libelle"] = searchForm.libelle;
    }
    if (searchForm.idClient !== "" && searchForm.idClient !== undefined) {
      filter["idClient"] = searchForm.idClient;
    }
    setContextValue("clientFilter", JSON.stringify(filter));
    setStorageValue("clientFilter", JSON.stringify(filter));
  };

  // search client form render. --------------------------------------------------
  return (
    <Paper sx={{ p: "0px", mb: "24px" }}>
      <form onSubmit={handleSubmit(handleSearch)}>
        {/* Action. -------------------------------------------------------- */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          {/* SearchTitle. ------------------------------------------------------ */}
          <Grid item xs>
            <Typography variant="h3" sx={{ p: "0", m: "30px  10px" }}>
              {t("clients.searchTitle")}
            </Typography>
          </Grid>

          {/* Reset. ------------------------------------------------------- */}
          <Grid item xs="auto" sx={{ p: "0", m: " 20px 0px 10px 0px" }}>
            <Button variant="text" onClick={handleSearchReset}>
              {t("clients.searchReset")}
            </Button>
          </Grid>

          {/* Submit. ------------------------------------------------------- */}
          <Grid item xs="auto" sx={{ p: "0", m: "20px 20px 10px 0px" }}>
            <Button type="submit" endIcon={<CobaltIcon name="search" />}>
              {t("clients.search")}
            </Button>
          </Grid>
        </Grid>

        {/* Formular. ------------------------------------------------------ */}
        <Grid
          container
          direction="row"
          justifyContent="start;"
          alignItems="stretch"
        >
          {/* Nom du client. ------------------------------------------------- */}
          <Grid item sx={{ width: "20%", p: "0", m: "0px  20px" }}>
            <Field
              as={TextField}
              id="libelle"
              label={t("clients.libelleLabel")}
              control={control}
              placeholder={t("clients.libellePlaceHolder")}
              rules={{}}
              errors={errors}
            />
          </Grid>

          {/* Status. ------------------------------------------------- */}
          <Grid item sx={{ width: "20%", p: "0", m: "0px  40px" }}>
            <Field
              as={TextField}
              id="idClient"
              label={t("clients.idClientLabel")}
              control={control}
              placeholder={t("clients.idClientPlaceHolder")}
              rules={{}}
              errors={errors}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
export default ClientFilter;
