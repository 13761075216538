export interface FluxTableData {
  noSort: string;
  id: string;
  col1: string;
  col2: string;
  col3: string;
  col4: string;
  col5: string;
  col6: string;
  col7: string;
  col8: string;
}
export interface FluxTableHeadCell {
  id: keyof FluxTableData;
  label: string;
  disablePadding: boolean;
  numeric: boolean;
  width: string;
}

export type FluxTableOrder = "asc" | "desc";

export const FluxPageRowsPageList = [10, 25, 50, 100];
