import React from "react";
import { useForm } from "react-hook-form";
import { Button, Grid, Paper, Typography } from "@mui/material";
import i18next from "i18next";
import { Trans } from "react-i18next";

import Field from "app/components/formField/Field";
import EmailField from "app/components/formField/EmailField";

import { PostResetPassword } from "services/login/PostResetPassword";

import { ResetBo } from "types/ResetBo.d";

// =============================================================================
// LoginReset...
// =============================================================================
interface LoginResetprop {
  onResetStatus: (type: string, message: string) => void;
}
const LoginReset: React.FC<LoginResetprop> = ({ onResetStatus }) => {
  // Members. ------------------------------------------------------------------
  const transField = {
    mailLabel: i18next.t("login.mailLabel"),
    mailPlaceHolder: i18next.t("login.mailPlaceHolder"),
    mailError: i18next.t("login.mailError"),
  };

  // Form control. -------------------------------------------------------------
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ResetBo>({
    defaultValues: { mail: "" },
  });

  // onSend. -------------------------------------------------------------------
  const onSend = (resetParameters: ResetBo) => {
    authenticateReset(resetParameters);
  };
  const { mutate: authenticateReset } = PostResetPassword({
    onSuccess: (data) => {
      onResetStatus("success", i18next.t("login.resetPasswordOK"));
    },
    onError: (error) => {
      onResetStatus("error", i18next.t("login.resetPasswordError"));
    },
  });

  // Reset password render. ----------------------------------------------------
  return (
    <form onSubmit={handleSubmit(onSend)}>
      <Grid item sx={{ mr: "120px" }}>
        <Paper
          elevation={3}
          sx={{
            width: "479px",
            height: "275px",
            flex: "0 auto",
            p: "30px",
            borderRadius: "10px",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{ height: "100%" }}
          >
            {/* Title. ----------------------------------------------------- */}
            <Grid item>
              <Typography variant="subtitle1" sx={{ color: "cobalt.lynch" }}>
                <Trans>login.resetTitle</Trans>
              </Typography>
            </Grid>

            {/* Formular. -------------------------------------------------- */}
            <Grid item container flexDirection="column">
              {/* mail. ---------------------------------------------------- */}
              <Field
                as={EmailField}
                id="mail"
                label={transField.mailLabel}
                control={control}
                placeholder={transField.mailPlaceHolder}
                rules={{
                  required: transField.mailError,
                }}
                errors={errors}
              />
            </Grid>

            {/* Action. ---------------------------------------------------- */}
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              {/* Cancel. -------------------------------------------------- */}
              <Button
                data-testid="login-reset-cancel"
                variant="text"
                onClick={() => onResetStatus("cancel", "")}
              >
                <Typography
                  sx={{
                    mt: "10px",
                    mb: "10px",
                    ml: "-20px",
                    color: "cobalt.bleuDigital100",
                    textDecoration: "underline",
                  }}
                >
                  <Trans>login.cancel</Trans>
                </Typography>
              </Button>

              {/* Send. ---------------------------------------------------- */}
              <Button data-testid="login-reset-send" type="submit">
                <Typography variant="h5" sx={{ color: "cobalt.white" }}>
                  <Trans>login.send</Trans>
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </form>
  );
};
export default LoginReset;
