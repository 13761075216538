import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// PostUser...
// =============================================================================
export const PostUser = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    {
      _key?: string;
      login: string;
      password: string;
      created_at?: string;
      created_by: string;
      role: number;
    }
  > = {},
) => {
  return useMutation(
    ({ _key, login, password, created_at, created_by, role }) =>
      Axios.post(
        "/user",
        { _key, login, password, created_at, created_by, role },
        { params: {} },
      ),
    {
      ...config,
    },
  );
};
