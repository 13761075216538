import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// GetClientList...
// =============================================================================
export const GetClientList = (
  config: UseMutationOptions<{ id_token: string }, AxiosError, {}> = {},
) => {
  // eslint-disable-next-line no-empty-pattern
  return useMutation(
    (clientFilter) =>
      Axios.get("/client/clients/", { params: { ...clientFilter } }),
    {
      ...config,
    },
  );
};
