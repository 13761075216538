import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// PostClient...
// =============================================================================
export const PostClient = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    {
      _key?: string;
      libelle: string;
      idsClient: string[];
      created_at?: string;
    }
  > = {},
) => {
  return useMutation(
    ({ _key, libelle, idsClient, created_at }) =>
      Axios.post(
        "/client",
        { _key, libelle, idsClient, created_at },
        { params: {} },
      ),
    {
      ...config,
    },
  );
};
