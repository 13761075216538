import React from "react";
import { Trans } from "react-i18next";
import { Grid, Paper, Typography } from "@mui/material";

import { CobaltIcon } from "@docaposte-agility/da-design-system";

// =============================================================================
// FluxDetailBoxMore1: display flux detail on box size 1/3 with expend.
// =============================================================================
interface FluxDetailBoxMore1Prop {
  id: string;
  value: string;
}
const FluxDetailBoxMore1: React.FC<FluxDetailBoxMore1Prop> = ({
  id,
  value,
}) => {
  const [more, setMore] = React.useState(false);
  return (
    <Grid item xs={6} sm={4} sx={{ p: "5px", m: "0px" }}>
      <Paper
        elevation={2}
        sx={{
          height: "100%",
          border: "1px solid",
          borderColor: "cobalt.grey10",
          borderRadius: "8px",
          p: "20px",
        }}
      >
        {/* Title. --------------------------------------------------------- */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {/* id: icon + label. -------------------------------------------- */}
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{ color: "cobalt.ultramarine" }}
            >
              <img
                src={"/images/icons/" + id + ".svg"}
                style={{
                  height: "18px",
                  marginRight: "10px",
                  verticalAlign: "middle",
                }}
                alt=""
              />
              <Trans>{"fluxDetail." + id}</Trans>
            </Typography>
          </Grid>

          {/* More/Less. --------------------------------------------------- */}
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{ color: "cobalt.bleuDigital100" }}
              data-testid="flux-detail-more1-moreLess"
              onClick={() => setMore(!more)}
            >
              <Trans>{"fluxDetail." + (more ? "less" : "more")}</Trans>
              <CobaltIcon
                name={more ? "chevron-up" : "chevron-down"}
                fontSize="10px"
                sx={{ ml: "5px", color: "cobalt.bleuDigital100" }}
              />
            </Typography>
          </Grid>
        </Grid>

        {/* Valeur more. ------------------------------------------------- */}
        {more && (
          <Grid container wrap="nowrap">
            <Grid
              item
              xs
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                width: "100px",
                height: "400px",
                overflow: "auto",
              }}
            >
              <pre>
                <code>
                  <Typography
                    variant="body1"
                    sx={{
                      mt: "10px",
                      color: "cobalt.lynch",
                    }}
                  >
                    {value || ". . ."}
                  </Typography>
                </code>
              </pre>
            </Grid>
          </Grid>
        )}

        {/* Valeur less. ------------------------------------------------- */}
        {!more && (
          <Grid container wrap="nowrap">
            <Grid
              item
              xs
              zeroMinWidth
              style={{
                width: "100px",
              }}
            >
              <Typography
                noWrap
                variant="body1"
                sx={{
                  mt: "10px",
                  color: "cobalt.lynch",
                }}
              >
                {value || ". . ."}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};
export default FluxDetailBoxMore1;
