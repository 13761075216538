import { FluxStatus } from "../enums/FluxStatus.d";
import { t } from "i18next";

// =============================================================================
// GetStatus...
// =============================================================================
export const GetStatus = () => {
  return [
    { value: FluxStatus.TOUS, label: t("flux.TOUS") },
    { value: FluxStatus.ERREUR, label: t("flux.ERREUR") },
    { value: FluxStatus.FIN_PRODUCTION, label: t("flux.FIN_PRODUCTION") },
    { value: FluxStatus.ANNULE, label: t("flux.ANNULE") },
  ];
};
