import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

import DateField from "app/components/formField/DateField";
import Field from "app/components/formField/Field";

import { PhidContext } from "utils/Contexts";

import { CobaltIcon } from "@docaposte-agility/da-design-system/";
import { t } from "i18next";
import { getStorageValue, setStorageValue } from "utils/MemoryStorage";

import DateNow from "services/date/DateNow";
import DateFormat from "services/date/DateFormat";
import { SearchDashboardForm } from "types/SearchDashboardForm";

// =============================================================================
// DashboardFilter...
// =============================================================================
interface DashboardFilterProp {}
const DashboardFilter: React.FC<DashboardFilterProp> = () => {
  // Members. ------------------------------------------------------------------
  const { setContextValue } = React.useContext(PhidContext);
  const [filterValues] = React.useState(
    JSON.parse(getStorageValue("dashboardFilter") || "{}"),
  );
  const startDate = DateFormat(DateNow("FirstDayOfMonth"), "yyyy-MM-dd");
  const endDate = DateFormat(DateNow(), "yyyy-MM-dd");
  // Form control. -------------------------------------------------------------
  const {
    handleSubmit,
    control,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = useForm<SearchDashboardForm>({
    defaultValues: {
      startDate: filterValues?.startDate ? filterValues.startDate : startDate,
      endDate: filterValues?.endDate ? filterValues.endDate : endDate,
    },
  });

  // handleSearch. -------------------------------------------------------------
  const handleSearch = (searchForm: SearchDashboardForm) => {
    let filter = {};
    if (searchForm.startDate !== "" && searchForm.startDate !== undefined) {
      filter["startDate"] = searchForm.startDate;
    }
    if (searchForm.endDate !== "" && searchForm.endDate !== undefined) {
      filter["endDate"] = searchForm.endDate;
    }
    setContextValue("dashboardFilter", JSON.stringify(filter));
    setStorageValue("dashboardFilter", JSON.stringify(filter));
    window.location.reload();
  };

  // search dashboard form render. --------------------------------------------------
  return (
    <Paper sx={{ p: "0px", mb: "24px" }}>
      <form onSubmit={handleSubmit(handleSearch)}>
        {/* Formular. ------------------------------------------------------ */}
        <Grid container direction="row" alignItems="center">
          {/* Text. -------------------------------------------------- */}
          <Grid item>
            <Typography variant="h2" sx={{ p: "0", m: "30px  20px" }}>
              {t("dashboard.subTitle")}
            </Typography>
          </Grid>

          {/* Date debut. -------------------------------------------------- */}
          <Grid item sx={{ mb: "8px" }}>
            <Field as={DateField} id="startDate" control={control} />
          </Grid>

          {/* Text. -------------------------------------------------- */}
          <Grid item>
            <Typography variant="h2" sx={{ p: "0", m: "30px  20px" }}>
              {t("dashboard.subTitle2")}
            </Typography>
          </Grid>

          {/* Date fin. ---------------------------------------------------- */}
          <Grid item sx={{ mb: "8px" }}>
            <Field as={DateField} id="endDate" control={control} />
          </Grid>
          {/* Search. ------------------------------------------------------- */}
          <Grid item xs="auto" sx={{ p: "0", m: "20px" }}>
            <Button type="submit" endIcon={<CobaltIcon name="search" />}>
              {t("dashboard.search")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
export default DashboardFilter;
