import React from "react";
import { Trans } from "react-i18next";
import {
  Checkbox,
  Chip,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  TablePagination,
  TableContainer,
  Menu,
  MenuItem,
} from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system/";

import { EditMode } from "enums/EditMode.d";
import {
  AccountsTableData,
  AccountsTableHeadCell,
  AccountsTableOrder,
  AccountsPageRowsPageList,
} from "types/AccountsTable.d";
import { AccountsType } from "enums/AccountType.d";

// =============================================================================
// EnhancedTableHead...
// =============================================================================
interface EnhancedTableProps {
  headCells: AccountsTableHeadCell[];
  numSelected: number;
  order: AccountsTableOrder;
  orderBy: string;
  rowCount: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AccountsTableData,
  ) => void;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    headCells,
    numSelected,
    order,
    orderBy,
    rowCount,
    onSelectAllClick,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof AccountsTableData) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  // Enhanced Table Head. ------------------------------------------------------
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}

// =============================================================================
// AccountsTable...
// =============================================================================
interface AccountsTableProps {
  type: AccountsType;
  rowData: AccountsTableData[];
  headCells: AccountsTableHeadCell[];
  onAction: (action: EditMode, rows: string[]) => void;
}
const AccountsTable: React.FC<AccountsTableProps> = ({
  type,
  rowData,
  headCells,
  onAction,
}) => {
  // Members. ------------------------------------------------------------------
  const [order, setOrder] = React.useState<AccountsTableOrder>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof AccountsTableData>("col1");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    AccountsPageRowsPageList[1],
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [rowEl, setRowEl] = React.useState<AccountsTableData | null>(null);
  const openMenu = Boolean(anchorEl);

  // When rowData updated. -----------------------------------------------------
  React.useEffect(() => {
    let selectFiltered = selected;
    selectFiltered = selectFiltered.filter(
      (id) => rowData.findIndex((user) => user.id === id) !== -1,
    );
    setSelected(selectFiltered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  // Sorting. ------------------------------------------------------------------
  function getComparator<Key extends keyof any>(
    order: AccountsTableOrder,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  // handles row. --------------------------------------------------------------
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    if (!openMenu) {
      const selectedIndex = selected.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      setSelected(newSelected);
      onAction(EditMode.SELECT, newSelected);
    }
  };
  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  // Handle paginator. ---------------------------------------------------------
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle header. ------------------------------------------------------------
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rowData.map((n) => n.id);
      setSelected(newSelecteds);
      onAction(EditMode.SELECT, newSelecteds);
      return;
    }
    setSelected([]);
    onAction(EditMode.SELECT, []);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AccountsTableData,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Handle menu. --------------------------------------------------------------
  const handleMenuOpen = (
    event: React.MouseEvent<HTMLElement>,
    row: AccountsTableData,
  ) => {
    setAnchorEl(event.currentTarget);
    setRowEl(row);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setRowEl(null);
  };
  const menuAction = (action: EditMode) => {
    if (rowEl) {
      onAction(action, [rowEl.id]);
    }
    handleMenuClose();
  };

  // Build Chips: convert col3 to chip when array type is "Clients". -----------
  const BuildChips = (col3) => {
    const chips = JSON.parse(col3).map((item: string, index: number) => (
      <Chip key={index} label={item} sx={{ mb: "5px", mr: "5px" }} />
    ));
    return chips;
  };

  // Phid User or Client table render. -----------------------------------------
  return (
    <Paper>
      <TableContainer>
        <Table>
          {/* Header. ------------------------------------------------------ */}
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rowData.length}
            headCells={headCells}
          />

          {/* Body. -------------------------------------------------------- */}
          <TableBody>
            {stableSort(rowData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.col1}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </TableCell>
                    <TableCell id={labelId} scope="row" padding="none">
                      {row.col1}
                    </TableCell>
                    <TableCell>{row.col2}</TableCell>
                    {type === AccountsType.USERS && (
                      <TableCell>{row.col3}</TableCell>
                    )}
                    {type === AccountsType.CLIENTS && (
                      <TableCell>{BuildChips(row.col3)}</TableCell>
                    )}
                    <TableCell>
                      <CobaltIcon
                        name="more-vertical"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleMenuOpen(e, row);
                          e.stopPropagation();
                        }}
                      />
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                          style: {
                            margin: "0px",
                            padding: "0px",
                          },
                        }}
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleMenuClose}
                        PaperProps={{
                          style: {
                            lineHeight: 70,
                            borderRadius: "0px",
                            border: "1px solid",
                            borderColor: "#e5e5fd", // cobalt.bleuDigital10
                          },
                        }}
                      >
                        <MenuItem
                          sx={{
                            margin: "0px",
                            padding: "5px",
                            backgroundColor: "cobalt.blanc",
                            borderBottom: "1px solid",
                            borderColor: "cobalt.bleuDigital10",
                          }}
                          onClick={() => menuAction(EditMode.UPDATE)}
                        >
                          <CobaltIcon name="edit-3" sx={{ mr: "10px" }} />
                          <Trans>table.update</Trans>
                        </MenuItem>
                        <MenuItem
                          onClick={() => menuAction(EditMode.DELETE)}
                          sx={{
                            margin: "0px",
                            padding: "5px",
                            backgroundColor: "cobalt.blanc",
                            color: "cobalt.redText",
                          }}
                        >
                          <CobaltIcon name="trash-2" sx={{ mr: "10px" }} />
                          <Trans>table.delete</Trans>
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginator. ------------------------------------------------------- */}
      <TablePagination
        rowsPerPageOptions={AccountsPageRowsPageList}
        count={rowData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
export default AccountsTable;
