import React from "react";
import { Trans } from "react-i18next";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import {
  CobaltIconNameType,
  Dialog,
} from "@docaposte-agility/da-design-system";

// =============================================================================
// AccountConfirm...
// =============================================================================
interface AccountConfirmProp {
  open: boolean;
  icon: CobaltIconNameType;
  title: string;
  message: string;
  button: string;
  onResponse: (confirmed: boolean) => void;
}
const AccountConfirm: React.FC<AccountConfirmProp> = ({
  open,
  icon,
  title,
  message,
  button,
  onResponse,
}) => {
  // Confirm render. -----------------------------------------------------------
  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={() => {
        onResponse(false);
      }}
    >
      {/* Title. ----------------------------------------------------------- */}
      <DialogTitle>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <CobaltIcon
              fontSize="26px"
              name={icon}
              sx={{ m: "0px", pr: "10px" }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h3" component="h4">
              {title}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>

      {/* Message. --------------------------------------------------------- */}
      <DialogContent dividers sx={{ p: "5px 32px" }}>
        <Typography variant="h3" component="h4">
          {message}
        </Typography>
      </DialogContent>

      {/* Action. ---------------------------------------------------------- */}
      <DialogActions>
        {/* Cancel. -------------------------------------------------------- */}
        <Button
          data-testid="account-confirm-cancel"
          onClick={() => {
            onResponse(false);
          }}
          color="primary"
          variant="text"
        >
          <Trans>confirm.cancel</Trans>
        </Button>
        {/* Save. ---------------------------------------------------------- */}
        <Button
          data-testid="account-confirm-save"
          onClick={() => {
            onResponse(true);
          }}
          sx={{ backgroundColor: "cobalt.redIcon", color: "cobalt.white" }}
        >
          <Trans>{button}</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AccountConfirm;
