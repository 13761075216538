// =============================================================================
// getStorageValue...
// =============================================================================
export const getStorageValue = (item: string) => {
  return sessionStorage.getItem(item);
};

// =============================================================================
// setStorageValue...
// =============================================================================
export const setStorageValue = (
  item: string,
  value?: string | number | boolean,
) => {
  switch (item) {
    case "route": {
      if (value !== undefined) {
        sessionStorage.setItem("route", String(value));
      } else {
        sessionStorage.removeItem("route");
      }
      break;
    }
    case "role": {
      if (value !== undefined) {
        sessionStorage.setItem("role", String(value));
      } else {
        sessionStorage.removeItem("role");
      }
      break;
    }
    case "token": {
      if (value !== undefined) {
        sessionStorage.setItem("token", String(value));
      } else {
        sessionStorage.removeItem("token");
      }
      break;
    }
    case "id": {
      if (value !== undefined) {
        sessionStorage.setItem("id", String(value));
      } else {
        sessionStorage.removeItem("id");
      }
      break;
    }
    case "fluxFilter": {
      if (value !== undefined) {
        sessionStorage.setItem("fluxFilter", String(value));
      } else {
        sessionStorage.removeItem("fluxFilter");
      }
      break;
    }
    case "dashboardFilter": {
      if (value !== undefined) {
        sessionStorage.setItem("dashboardFilter", String(value));
      } else {
        sessionStorage.removeItem("dashboardFilter");
      }
      break;
    }
  }
};
