import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// GetUserList...
// =============================================================================
export const GetUserList = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    { id: string }
  > = {},
) => {
  return useMutation(
    ({ id }) => Axios.get("/user/users/" + id, { params: {} }),
    {
      ...config,
    },
  );
};
