import React from "react";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { Button, Grid, Typography } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system/";

import AccountsTable from "app/components/accounts/AccountsTable";
import AccountsEdit from "app/components/accounts/AccountsEdit";

import DateFormat from "services/date/DateFormat";
import { GetClientList } from "services/clients/GetClientList";
import { GetUserList } from "services/users/GetUserList";

import { ConvertRole2Value } from "utils/ConvertRole2Value";
import { PhidContext } from "utils/Contexts";
import { getStorageValue } from "utils/MemoryStorage";

import { ClientForm } from "types/ClientForm.d";
import { UserForm } from "types/UserForm.d";
import { Account } from "types/Account.d";
import {
  AccountsTableData,
  AccountsTableHeadCell,
} from "types/AccountsTable.d";

import { EditMode } from "enums/EditMode.d";
import { AccountsType } from "enums/AccountType.d";
import ClientFilter from "./ClientFilter";

// =============================================================================
// AccountsList...
// =============================================================================
interface AccountsListProp {
  type: AccountsType;
}
const AccountsList: React.FC<AccountsListProp> = ({ type }) => {
  // Members. ------------------------------------------------------------------
  const { clientFilter, setContextValue } = React.useContext(PhidContext);
  const [deleteButton, setDeleteButton] = React.useState("");
  const [rowData, setRowData] = React.useState<AccountsTableData[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [editMode, setEditMode] = React.useState<EditMode>(EditMode.NONE);
  const [clientForm, setClientForm] = React.useState<ClientForm>({
    _key: "",
    libelle: "",
    idsClient: [],
    created_at: "",
  });
  const [userFrom, setUserForm] = React.useState<UserForm>({
    _key: "",
    identifiant: "",
    created_at: "",
    role: "",
    password: "",
  });
  const [deleteList, setDeleteList] = React.useState<string[]>([]);

  // Table cell header. --------------------------------------------------------
  const headCells: AccountsTableHeadCell[] = [
    {
      id: "col1",
      label: i18next.t(type + ".headerCol1"),
      numeric: false,
      disablePadding: true,
    },
    {
      id: "col2",
      label: i18next.t(type + ".headerCol2"),
      numeric: false,
      disablePadding: false,
    },
    {
      id: "col3",
      label: i18next.t(type + ".headerCol3"),
      numeric: false,
      disablePadding: false,
    },
  ];

  // Init on first call. -------------------------------------------------------
  React.useEffect(() => {
    setContextValue("backdrop", true);
    if (type === AccountsType.CLIENTS) {
      getClientList({});
    } else {
      getUserList({ id: getStorageValue("id") || "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Apply filter. -------------------------------------------------------------
  React.useEffect(() => {
    if (type === AccountsType.CLIENTS) {
      const filter = { ...clientFilter };
      setContextValue("backdrop", true);
      console.log(filter);
      getClientList(filter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientFilter]);

  // BackOffice response. ------------------------------------------------------
  const { mutate: getClientList } = GetClientList({
    onSuccess: (data) => {
      const res = data["data"];
      const loadedRowData = res.clients.map((item) => ({
        id: item._key,
        col1: item.libelle,
        col2: DateFormat(item.created_at, i18next.t("common.date")),
        col3: JSON.stringify(item.idsClient),
        extra: "",
      }));
      setRowData(loadedRowData);
      setContextValue("backdrop", false);
    },
    onError: (error) => {
      setRowData([]);
      setContextValue("backdrop", false);
    },
  });
  const { mutate: getUserList } = GetUserList({
    onSuccess: (data) => {
      const res = data["data"];
      const loadedRowData = res.users.map((item) => ({
        id: item._key,
        col1: item.login,
        col2: DateFormat(item.created_at, i18next.t("common.date")),
        col3: i18next.t("userRole." + item.role),
        extra: item.password || "***",
      }));
      setRowData(loadedRowData);
      setContextValue("backdrop", false);
    },
    onError: (error) => {
      setRowData([]);
      setContextValue("backdrop", false);
    },
  });

  // handlers. -----------------------------------------------------------------
  const handleAction = (mode: EditMode, rows: string[]) => {
    switch (mode) {
      // Select. ---------------------------------------------------------------
      case EditMode.SELECT:
        setSelected(rows);
        if (rows.length === 1) {
          setDeleteButton(i18next.t(type + ".deleteOne"));
        } else if (rows.length > 1) {
          setDeleteButton(
            i18next
              .t(type + ".deleteSeveral")
              .replace("__NB__", rows.length.toString()),
          );
        } else {
          setDeleteButton("");
        }
        setEditMode(EditMode.NONE);
        break;

      // Delete selected. ------------------------------------------------------
      case EditMode.DELETESELECTED:
        setDeleteList(rows);
        setEditMode(EditMode.DELETESELECTED);
        break;

      // Create. ---------------------------------------------------------------
      case EditMode.CREATE:
        if (type === AccountsType.CLIENTS) {
          setClientForm({
            _key: "",
            libelle: "",
            idsClient: [],
            created_at: "",
          });
        } else {
          setUserForm({
            _key: "",
            identifiant: "",
            created_at: "",
            role: "",
            password: "",
          });
        }
        setEditMode(EditMode.CREATE);
        break;

      // Update. ---------------------------------------------------------------
      case EditMode.UPDATE:
        const foundUpdate = rowData.find((elem) => {
          return elem.id === rows[0];
        });
        if (foundUpdate) {
          if (type === AccountsType.CLIENTS) {
            setClientForm({
              _key: foundUpdate.id,
              libelle: foundUpdate.col1,
              created_at: foundUpdate.col2,
              idsClient: JSON.parse(foundUpdate.col3),
            });
          } else {
            setUserForm({
              _key: foundUpdate.id,
              identifiant: foundUpdate.col1,
              created_at: foundUpdate.col2,
              role: ConvertRole2Value(foundUpdate.col3),
              password: foundUpdate.extra,
            });
          }
          setEditMode(EditMode.UPDATE);
        }
        break;

      // Delete. ---------------------------------------------------------------
      case EditMode.DELETE:
        const foundDelete = rowData.find((elem) => {
          return elem.id === rows[0];
        });

        if (foundDelete) {
          if (type === AccountsType.CLIENTS) {
            setClientForm({
              _key: foundDelete.id,
              libelle: foundDelete.col1,
              created_at: foundDelete.col2,
              idsClient: JSON.parse(foundDelete.col3),
            });
          } else {
            setUserForm({
              _key: foundDelete.id,
              identifiant: foundDelete.col1,
              created_at: foundDelete.col2,
              role: ConvertRole2Value(foundDelete.col3),
              password: foundDelete.extra,
            });
          }
          setEditMode(EditMode.DELETE);
        }
        break;
    }
  };
  const handleEditResults = (action: EditMode, account: Account) => {
    // Delete. -----------------------------------------------------------------
    if (action === EditMode.DELETE || action === EditMode.DELETESELECTED) {
      const deleted = account?.selected || [];
      const rowFiltered = rowData.filter(
        (item) => deleted.indexOf(item.id) === -1,
      );
      const selectFiltered = selected.filter(
        (item) => deleted.indexOf(item) === -1,
      );
      setRowData(rowFiltered);
      handleAction(EditMode.SELECT, selectFiltered);
    }

    setEditMode(EditMode.NONE);
  };

  // Client form render. -------------------------------------------------------
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ pr: "24px" }}
      >
        {/* Title. --------------------------------------------------------- */}
        <Grid item>
          <Typography
            variant="h1"
            sx={{ m: "24px", color: "cobalt.ultramarine" }}
          >
            {type === AccountsType.CLIENTS ? (
              <Trans>clients.title</Trans>
            ) : (
              <Trans>users.title</Trans>
            )}
          </Typography>
        </Grid>

        {/* Actions. ------------------------------------------------------- */}
        <Grid item>
          {/* Delete. ------------------------------------------------------ */}
          {deleteButton && (
            <Button
              data-testid="account-list-delete"
              variant="text"
              startIcon={<CobaltIcon name="trash-2" />}
              sx={{ color: "cobalt.redText" }}
              onClick={() => handleAction(EditMode.DELETESELECTED, selected)}
            >
              {deleteButton}
            </Button>
          )}

          {/* Create. ------------------------------------------------------ */}
          <Button
            data-testid="account-list-create"
            startIcon={<CobaltIcon name="plus" />}
            onClick={() => handleAction(EditMode.CREATE, [])}
          >
            {type === AccountsType.CLIENTS ? (
              <Trans>clients.create</Trans>
            ) : (
              <Trans>users.create</Trans>
            )}
          </Button>
        </Grid>
      </Grid>

      {/* Filter. ------------------------------------------------------ */}
      {type === AccountsType.CLIENTS ? (
        <Grid sx={{ p: "0px 24px" }}>
          <ClientFilter />
        </Grid>
      ) : (
        <Grid sx={{ p: "0px 24px" }}></Grid>
      )}

      {/* Main. ------------------------------------------------------------ */}
      <Grid sx={{ p: "0px 24px" }}>
        {/* Accounts table List. ------------------------------------------- */}
        <AccountsTable
          type={type}
          rowData={rowData}
          headCells={headCells}
          onAction={handleAction}
        />

        {/* Accounts edit and delete. -------------------------------------- */}
        {editMode !== "" && (
          <AccountsEdit
            type={type}
            mode={editMode}
            clientForm={clientForm}
            userForm={userFrom}
            selected={deleteList}
            rowData={rowData}
            onEditResults={handleEditResults}
          />
        )}
      </Grid>
    </>
  );
};
export default AccountsList;
