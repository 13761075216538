import { Button, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";

import DateField from "app/components/formField/DateField";
import Field from "app/components/formField/Field";
import TextField from "app/components/formField/TextField";

import { PhidContext } from "utils/Contexts";

import { CobaltIcon } from "@docaposte-agility/da-design-system/";
import SelectField from "app/components/formField/SelectField";
import { t } from "i18next";
import { getStorageValue, setStorageValue } from "utils/MemoryStorage";

import { SearchFluxForm } from "types/SearchFluxForm";
import { GetStatus } from "utils/GetStatus";
import { GetFluxList } from "../../../services/flux/GetFluxList";
import { ExportCsv, MAX_EXPORT } from "../../../utils/ExportUtils";
import { FluxStatus } from "enums/FluxStatus.d";

// =============================================================================
// FluxFilter...
// =============================================================================
interface FluxFilterProp {}
const FluxFilter: React.FC<FluxFilterProp> = () => {
  // Members. ------------------------------------------------------------------
  const { setContextValue } = React.useContext(PhidContext);
  const [filterValues] = React.useState(
    JSON.parse(getStorageValue("fluxFilter") || "{}"),
  );
  const [availableStatus] = React.useState(GetStatus());
  let count: number = MAX_EXPORT;
  let offset: number = 0;
  // Form control. -------------------------------------------------------------
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<SearchFluxForm>({
    defaultValues: {
      client: filterValues?.client,
      status: filterValues?.status,
      flux: filterValues?.flux,
      startDate: filterValues?.startDate,
      endDate: filterValues?.endDate,
    },
  });

  // handleSearchReset. --------------------------------------------------------
  const handleSearchReset = () => {
    setValue("client", "");
    setValue("status", "");
    setValue("flux", "");
    setValue("startDate", "");
    setValue("endDate", "");
    setContextValue("fluxFilter", "{}");
    setStorageValue("fluxFilter", "{}");
  };

  // handleExport. --------------------------------------------------------
  const handleExport = () => {
    const countOffset = { count, offset };
    const filter = {
      ...JSON.parse(getStorageValue("fluxFilter") || "{}"),
      ...countOffset,
    };
    setContextValue("toasterSuccess", "Export en cours...");
    getFluxList(filter);
  };

  // Retrieve flux list fron backend. ----------------------------------------
  const { mutate: getFluxList } = GetFluxList({
    onSuccess: (data) => {
      const response = data["data"];
      const NewRowData = response.results.map((item) => ({
        col1: item.status || "",
        col2: item.date ? item.date.replace(/T/, " ").replace(/\..+/, "") : "",
        col3: item._keyModified ? item._keyModified : item._key || "",
        col4: item.racine || "",
        col5: item.infoReception || "",
        col6: item.type || "",
        col7: item.codeClientBU || "",
        col8: item.codeAppli || "",
      }));
      const csv = ExportCsv(NewRowData);
      const blob = new Blob([csv], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "export_phid_" + new Date().toISOString() + ".csv";
      link.click();
    },
    onError: (error) => {
      setContextValue("toasterError", "ERROR");
    },
  });

  // handleSearch. -------------------------------------------------------------
  const handleSearch = (searchForm: SearchFluxForm) => {
    let filter = {};
    if (searchForm.client !== "" && searchForm.client !== undefined) {
      filter["client"] = searchForm.client;
    }
    if (searchForm.status !== "" && searchForm.status !== undefined) {
      filter["status"] =
        searchForm.status === FluxStatus.TOUS ? undefined : searchForm.status;
    }
    if (searchForm.flux !== "" && searchForm.flux !== undefined) {
      filter["flux"] = searchForm.flux;
    }
    if (searchForm.startDate !== "" && searchForm.startDate !== undefined) {
      filter["startDate"] = searchForm.startDate;
    }
    if (searchForm.endDate !== "" && searchForm.endDate !== undefined) {
      filter["endDate"] = searchForm.endDate;
    }
    console.log(filter);
    setContextValue("fluxFilter", JSON.stringify(filter));
    setStorageValue("fluxFilter", JSON.stringify(filter));
  };

  // search flux form render. --------------------------------------------------
  return (
    <Paper sx={{ p: "0px", mb: "24px" }}>
      <form onSubmit={handleSubmit(handleSearch)}>
        {/* Action. -------------------------------------------------------- */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          {/* SearchTitle. ------------------------------------------------------ */}
          <Grid item xs>
            <Typography variant="h3" sx={{ p: "0", m: "30px  10px" }}>
              {t("flux.searchTitle")}
            </Typography>
          </Grid>

          {/* Reset. ------------------------------------------------------- */}
          <Grid item xs="auto" sx={{ p: "0", m: " 20px 0px 10px 0px" }}>
            <Button variant="text" onClick={handleSearchReset}>
              {t("flux.searchReset")}
            </Button>
          </Grid>

          {/* Submit. ------------------------------------------------------- */}
          <Grid item xs="auto" sx={{ p: "0", m: "20px 20px 10px 0px" }}>
            <Button type="submit" endIcon={<CobaltIcon name="search" />}>
              {t("flux.search")}
            </Button>
          </Grid>

          {/* Export. ------------------------------------------------------- */}
          <Grid item xs="auto" sx={{ p: "0", m: "20px 20px 10px 0px" }}>
            <Button
              endIcon={<CobaltIcon name="file-csv" />}
              onClick={handleExport}
            >
              {t("flux.export")}
            </Button>
          </Grid>
        </Grid>

        {/* Formular. ------------------------------------------------------ */}
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="stretch"
        >
          {/* Nom du client. ------------------------------------------------- */}
          <Grid item sx={{ width: "20%" }}>
            <Field
              as={TextField}
              id="client"
              label={t("flux.clientLabel")}
              control={control}
              placeholder={t("flux.clientPlaceHolder")}
              rules={{}}
              errors={errors}
            />
          </Grid>

          {/* Status. ------------------------------------------------- */}
          <Grid item sx={{ width: "15%" }}>
            <Field
              as={SelectField}
              id="status"
              label={t("flux.statusLabel")}
              control={control}
              placeholder={t("flux.statusPlaceHolder")}
              menuItems={availableStatus}
              errors={errors}
            />
          </Grid>

          {/* Id du flux. ---------------------------------------------------- */}
          <Grid item sx={{ width: "20%" }}>
            <Field
              as={TextField}
              id="flux"
              label={t("flux.fluxLabel")}
              control={control}
              placeholder={t("flux.fluxPlaceHolder")}
              rules={{}}
              errors={errors}
            />
          </Grid>

          {/* Date debut. -------------------------------------------------- */}
          <Grid item sx={{ width: "15%" }}>
            <Field
              as={DateField}
              id="startDate"
              label={t("flux.startDateLabel")}
              control={control}
              rules={{}}
              errors={errors}
            />
          </Grid>

          {/* Date fin. ---------------------------------------------------- */}
          <Grid item sx={{ width: "15%" }}>
            <Field
              as={DateField}
              id="endDate"
              label={t("flux.endDateLabel")}
              control={control}
              rules={{}}
              errors={errors}
            />
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
export default FluxFilter;
