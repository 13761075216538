import { Grid, Typography } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";

import FluxBoxKpi from "app/components/flux/FluxBoxKpi";
import NavDrawer from "app/components/NavDrawer";

import { PhidContext } from "utils/Contexts";
import { getStorageValue, setStorageValue } from "utils/MemoryStorage";

import DateNow from "services/date/DateNow";
import { GetFluxKpi } from "services/flux/GetFluxKpi";
import DashboardFilter from "../components/dashboard/DashboardFilter";

// =============================================================================
// Dashboard...
// =============================================================================
const Dashboard = () => {
  // Members. ------------------------------------------------------------------
  const { navRoutes, setContextValue } = React.useContext(PhidContext);
  const transField = {
    kpiErrorTitle: t("dashboard.kpiErrorTitle"),
    kpiInProgressTitle: t("dashboard.kpiInprogressTitle"),
    kpiFinishedTitle: t("dashboard.kpiFinishedTitle"),
  };
  let navigate = useNavigate();
  const [accessAllowed, setAccessAllowed] = React.useState(false);
  const [filterValues] = React.useState(
    JSON.parse(getStorageValue("dashboardFilter") || "{}"),
  );
  const startDate = filterValues?.startDate
    ? filterValues.startDate
    : DateNow("FirstDayOfMonth");
  const endDate = filterValues?.endDate ? filterValues.endDate : DateNow();
  // Check access. -------------------------------------------------------------
  React.useEffect(() => {
    if (getStorageValue("role")) {
      if (Object.keys(navRoutes).length !== 0) {
        if (Object.keys(navRoutes).indexOf("dashboard") !== -1) {
          setStorageValue("route", "/phid/dashboard");
          setAccessAllowed(true);
          setContextValue("backdrop", true);
          getFluxKpi({
            start: startDate + "T00:00:00.000Z",
            end: endDate + "T23:59:59.999Z",
          });
        } else {
          navigate("/phid/notFound", { replace: true });
        }
      }
    } else {
      navigate("/phid", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navRoutes]);

  // Retrieve pki flux fron backend. -------------------------------------------
  const [flux, setFlux] = React.useState({});
  const { mutate: getFluxKpi } = GetFluxKpi({
    onSuccess: (data) => {
      const res = data["data"];
      setFlux(res);
      setContextValue("backdrop", false);
    },
    onError: (error) => {
      setFlux({
        kpiError: "?",
        kpiInProgress: "?",
        kpiFinished: "?",
      });
      setContextValue("backdrop", false);
    },
  });

  // Build pki list box. -------------------------------------------------------
  const kpiList = Object.keys(flux).map((kpi: string) => {
    return (
      <FluxBoxKpi
        key={kpi}
        type={kpi}
        title={transField[kpi + "Title"]}
        value={flux[kpi]}
      />
    );
  });

  // Dashboard render. ---------------------------------------------------------
  return (
    <>
      {accessAllowed && (
        <NavDrawer mode="dashboard">
          {/* Title. ----------------------------------------------------------- */}
          <Typography
            variant="h1"
            sx={{ m: "24px 24px 12px 24px", color: "cobalt.ultramarine" }}
          >
            {t("dashboard.title")}
          </Typography>

          {/* Filter. ------------------------------------------------------ */}
          <DashboardFilter />

          {/* Flux pki box. ---------------------------------------------------- */}
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {kpiList}
          </Grid>
        </NavDrawer>
      )}
    </>
  );
};
export default Dashboard;
