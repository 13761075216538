import React from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Grid, Paper, Typography, InputAdornment } from "@mui/material";
import { Trans } from "react-i18next";
import i18next from "i18next";
import { CobaltIcon } from "@docaposte-agility/da-design-system";

import Field from "app/components/formField/Field";
import TextField from "app/components/formField/TextField";
import PasswordField from "app/components/formField/PasswordField";

import { PostAuthenticate } from "services/login/PostAuthenticate";

import { PhidContext } from "utils/Contexts";
import { setStorageValue } from "utils/MemoryStorage";

import { LoginBo } from "types/LoginBo.d";

// =============================================================================
// LoginForm...
// =============================================================================
interface LoginFormProp {
  onResetPassword: () => void;
  onLoginStatus: (type: string, message: string) => void;
  onDisabled: boolean;
}
const LoginForm: React.FC<LoginFormProp> = ({
  onResetPassword,
  onLoginStatus,
  onDisabled,
}) => {
  // Members. ------------------------------------------------------------------
  const { mode, setContextValue } = React.useContext(PhidContext);
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const transField = {
    loginLabel: i18next.t("login.loginLabel"),
    loginPlaceHolder: i18next.t("login.loginPlaceHolder"),
    loginError: i18next.t("login.loginError"),
    passwordLabel: i18next.t("login.passwordLabel"),
    passwordPlaceHolder: i18next.t("login.passwordPlaceHolder"),
    passwordError: i18next.t("login.passwordError"),
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [user, setUser] = React.useState("");

  // Form control. -------------------------------------------------------------
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<LoginBo>({
    defaultValues: { login: "", password: "" },
  });

  // handleConnect. ----------------------------------------------------------------
  const handleConnect = (loginParameters: LoginBo) => {
    setUser(loginParameters.login);
    authenticateLogin(loginParameters);
  };
  const { mutate: authenticateLogin } = PostAuthenticate({
    onSuccess: (data) => {
      const res = data["data"];
      setStorageValue("role", res.user.role);
      setStorageValue("id", res.user._key);
      if (mode !== "simul") {
        setStorageValue("token", res.token.id_token);
      } else {
        setStorageValue("token");
      }
      setContextValue("userRoutes", res.user.role);
      setContextValue("drawer", true);
      navigate("/phid/dashboard");
    },
    onError: (error) => {
      onLoginStatus("error", i18next.t("login.Error"));
    },
  });

  // login form render. --------------------------------------------------------
  return (
    <form onSubmit={handleSubmit(handleConnect)}>
      <Grid item sx={{ mr: "185px" }}>
        <Paper
          elevation={3}
          sx={{
            width: "414px",
            height: "472px",
            flex: "0 auto",
            p: "40px",
            borderRadius: "10px",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            sx={{ height: "100%" }}
          >
            {/* Title. ------------------------------------------------------- */}
            <Grid item>
              <Typography variant="h2" sx={{ color: "cobalt.lynch" }}>
                <Trans>login.loginTitle</Trans>
              </Typography>
              <Typography variant="h1" sx={{ color: "cobalt.bleuDigital100" }}>
                <Trans>common.produit</Trans>
              </Typography>
            </Grid>

            {/* Formular. */}
            <Grid item container flexDirection="column">
              {/* login. --------------------------------------------------- */}
              <Field
                as={TextField}
                id="login"
                label={transField.loginLabel}
                control={control}
                disabled={onDisabled}
                placeholder={transField.loginPlaceHolder}
                rules={{
                  required: transField.loginError,
                }}
                errors={errors}
              />

              {/* password. ------------------------------------------------ */}
              <br></br>
              <Field
                as={showPassword ? TextField : PasswordField}
                id="password"
                label={transField.passwordLabel}
                control={control}
                disabled={onDisabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CobaltIcon
                        name={showPassword ? "eye-off" : "eye"}
                        aria-label="toggle password visibility"
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => setShowPassword(!showPassword)}
                      />
                    </InputAdornment>
                  ),
                }}
                placeholder={transField.passwordPlaceHolder}
                rules={{
                  required: transField.passwordError,
                }}
                errors={errors}
              />
            </Grid>

            {/* Action. ---------------------------------------------------- */}
            <Grid item>
              {/* Reset password. ------------------------------------------ */}
              <Button
                data-testid="login-form-resetpassword"
                variant="text"
                disabled={onDisabled}
                onClick={onResetPassword}
              >
                <Typography
                  sx={{
                    mb: "10px",
                    ml: "-20px",
                    color: "cobalt.bleuDigital100",
                    textDecoration: "underline",
                  }}
                >
                  <Trans>login.PasswordForget</Trans>
                </Typography>
              </Button>

              {/* Connect. ------------------------------------------------- */}
              <Button
                data-testid="login-form-connect"
                sx={{ width: "100%" }}
                type="submit"
                disabled={onDisabled}
              >
                <Typography variant="h5" sx={{ color: "cobalt.white" }}>
                  <Trans>login.connect</Trans>
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </form>
  );
};
export default LoginForm;
