import React from "react";
import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import i18next from "i18next";
import {
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Dialog } from "@docaposte-agility/da-design-system";
import { CobaltIcon } from "@docaposte-agility/da-design-system/";

import { PhidContext } from "utils/Contexts";
import AccountConfirm from "app/components/accounts/AccountConfirm";
import Field from "app/components/formField/Field";
import TextField from "app/components/formField/TextField";

import DateFormat from "services/date/DateFormat";
import { PostClient } from "services/clients/PostClient";
import { PatchClient } from "services/clients/PatchClient";
import { DeleteClient } from "services/clients/DeleteClient";

import { Account } from "types/Account.d";
import { AccountsTableData } from "types/AccountsTable.d";
import { ClientForm } from "types/ClientForm.d";
import { ClientBoPost } from "types/ClientBoPost.d";
import { ClientBoPatch } from "types/ClientBoPatch.d";
import { EditMode } from "enums/EditMode.d";

let toBeDeleted: number = 0;
let deleteOK: string[] = [];
let deleteWIP: string[] = [];

// =============================================================================
// ClientEdit...
// =============================================================================
interface ClientEditProp {
  mode: EditMode;
  clientForm: ClientForm;
  selected: string[];
  onEditAction: (mode: EditMode, status: string, account?: Account) => void;
}
const ClientEdit: React.FC<ClientEditProp> = ({
  mode,
  clientForm,
  selected,
  onEditAction,
}) => {
  // Members. ------------------------------------------------------------------
  const { setContextValue } = React.useContext(PhidContext);
  const [open, setOpen] = React.useState(true);
  const [libelleDisabled, setLibelleDisabled] = React.useState(false);
  const [addClientIdDisabled, setAddClientIdDisabled] = React.useState(false);
  const [idsClientChip, setClientIdChip] = React.useState<string[]>([]);
  const [rowClient, setRowClient] = React.useState<AccountsTableData>({
    id: "",
    col1: "",
    col2: "",
    col3: "",
    extra: "",
  });

  // Form control. -------------------------------------------------------------
  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<ClientForm>({
    defaultValues: {
      libelle: "",
      idsClient: [],
      extra: "",
    },
  });

  // Init formular on mode or client input changes. ----------------------------
  React.useEffect(() => {
    setOpen(true);
    setValue("libelle", clientForm.libelle);
    setValue("idsClient", clientForm.idsClient);
    setValue("extra", "");
    setClientIdChip(clientForm.idsClient);
    setLibelleDisabled(mode !== EditMode.CREATE);
    setAddClientIdDisabled(mode !== EditMode.CREATE);
    setRowClient({
      id: clientForm._key,
      col1: clientForm.libelle,
      col2: DateFormat(clientForm.created_at, i18next.t("common.date")),
      col3: JSON.stringify(clientForm.idsClient),
      extra: clientForm.extra || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, clientForm, selected]);

  // handlers. -----------------------------------------------------------------
  const handleSave = (clientForm: ClientForm) => {
    clientForm.idsClient = idsClientChip;

    // Create. -----------------------------------------------------------------
    if (mode === EditMode.CREATE) {
      setContextValue("backdrop", true);
      let clientBo: ClientBoPost = {
        libelle: clientForm?.libelle,
        idsClient: idsClientChip,
      };
      createClient(clientBo);
    }

    // Update. -----------------------------------------------------------------
    else if (mode === EditMode.UPDATE) {
      setContextValue("backdrop", true);
      let clientBo: ClientBoPatch = {
        _key: rowClient.id,
      };
      if (clientForm?.libelle !== rowClient.col1) {
        clientBo["libelle"] = clientForm?.libelle;
      }
      if (JSON.stringify(clientForm?.idsClient) !== rowClient.col3) {
        clientBo["idsClient"] = clientForm?.idsClient;
      }
      if (Object.keys(clientBo).length > 1) {
        updateClient(clientBo);
      } else {
        onEditAction(EditMode.CANCEL, "");
      }
    }
    setOpen(false);
  };
  const handleDeleteAction = (confirmed: boolean) => {
    if (confirmed) {
      setContextValue("backdrop", true);
      deleteOK = [];
      deleteWIP = [];

      // Delete. ---------------------------------------------------------------
      if (mode === EditMode.DELETE) {
        toBeDeleted = 1;
        deleteWIP.push(clientForm._key);
        deleteClient({ id: clientForm._key });
      }

      // Delete selected. ------------------------------------------------------
      else if (mode === EditMode.DELETESELECTED) {
        deleteWIP = selected;
        toBeDeleted = deleteWIP.length;
        selected.forEach((elemId) => {
          deleteClient({ id: elemId });
        });
      }
    } else {
      onEditAction(EditMode.CANCEL, "");
    }
    setOpen(false);
  };

  // handle Chips management. --------------------------------------------------
  const handleChipsDelete = (chipToDelete: string) => () => {
    setClientIdChip((idsClientChip) =>
      idsClientChip.filter((chip) => chip !== chipToDelete),
    );
  };
  const handleAddCliendId = () => {
    const AddClientId = getValues("extra");
    if (AddClientId !== "") {
      setClientIdChip((idsClientChip) => [...idsClientChip, AddClientId || ""]);
      setValue("extra", "");
    }
  };

  // BackOffice response. ------------------------------------------------------
  const { mutate: createClient } = PostClient({
    onSuccess: (data) => {
      const resData = data["data"];
      const addedClient: Account = {
        client: {
          _key: resData.new._key,
          libelle: resData.new.libelle,
          idsClient: resData.new.idsClient,
          created_at: resData.new.created_at,
        },
      };
      onEditAction(mode, "OK", addedClient);
    },
    onError: (error) => {
      onEditAction(mode, "ERROR");
    },
  });
  const { mutate: updateClient } = PatchClient({
    onSuccess: (data) => {
      const resConfig = data["config"];
      const param = JSON.parse(resConfig.data);
      const UpdatedClient: Account = {
        client: {
          _key: rowClient.id,
          libelle: param.libelle !== undefined ? param.libelle : rowClient.col1,
          created_at: rowClient.col2,
          idsClient:
            param.idsClient !== undefined
              ? param.idsClient
              : JSON.parse(rowClient.col3),
        },
      };
      onEditAction(mode, "OK", UpdatedClient);
    },
    onError: (error) => {
      const UpdatedClient: Account = {
        client: {
          _key: rowClient.id,
          libelle: rowClient.col1,
          created_at: rowClient.col2,
          idsClient: JSON.parse(rowClient.col3),
        },
      };
      onEditAction(mode, "ERROR", UpdatedClient);
    },
  });
  const { mutate: deleteClient } = DeleteClient({
    onSuccess: (data) => {
      const resConfig = data["config"];
      const index = resConfig.url.replace("/client/", "");
      deleteOK.push(index);
      toBeDeleted = toBeDeleted - 1;
      deleteWIP = deleteWIP.filter((row) => row !== index);
      if (toBeDeleted === 0) {
        if (deleteWIP.length !== 0) {
          onEditAction(mode, "ERROR", { selected: deleteOK });
        } else {
          onEditAction(mode, "OK", { selected: deleteOK });
        }
      }
    },
    onError: (error) => {
      toBeDeleted = toBeDeleted - 1;
      if (toBeDeleted === 0) {
        onEditAction(mode, "ERROR", { selected: deleteOK });
      }
    },
  });

  // Confirm messages. ---------------------------------------------------------
  const confirmMessage = (msgType: string) => {
    if (msgType === "Title") {
      return i18next.t("confirm.deleteTitle");
    } else if (msgType === "Message") {
      if (mode === EditMode.DELETE) {
        return i18next
          .t("confirm.deleteMessage")
          .replace("__USER__", '"' + clientForm.libelle + '"');
      } else {
        return i18next
          .t("confirm.deleteMessage")
          .replace(
            "__USER__",
            selected.length +
              i18next.t("confirm.clients") +
              (selected.length > 1 ? "s" : ""),
          );
      }
    } else if (msgType === "Button") {
      if (mode === EditMode.DELETE) {
        return (
          i18next.t("confirm.deleteButton").replace("__USER__", "1") +
          i18next.t("confirm.clients")
        );
      } else {
        return (
          i18next
            .t("confirm.deleteButton")
            .replace("__USER__", "" + selected.length) +
          i18next.t("confirm.clients") +
          (selected.length > 1 ? "s" : "")
        );
      }
    } else {
      return "__" + msgType + "__";
    }
  };

  // Client form render. -------------------------------------------------------
  return (
    <>
      {/* Edit formular. --------------------------------------------------- */}
      {(mode === EditMode.CREATE || mode === EditMode.UPDATE) && (
        <Dialog
          open={open}
          maxWidth="sm"
          fullWidth={true}
          PaperProps={{
            sx: {
              minHeight: "400px",
            },
          }}
          onClose={() => {
            setOpen(false);
            onEditAction(EditMode.CANCEL, "");
          }}
        >
          {/* Title. ----------------------------------------------------------- */}
          <DialogTitle>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <CobaltIcon
                  fontSize="26px"
                  name="layers"
                  sx={{ m: "0px", pr: "10px" }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h3" component="h4">
                  <Trans>
                    {mode === EditMode.CREATE
                      ? "clients.createTitle"
                      : "clients.updateTitle"}
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>

          {/* Formular. -------------------------------------------------------- */}
          <form onSubmit={handleSubmit(handleSave)}>
            <DialogContent dividers sx={{ p: "5px 32px" }}>
              {/* Libelle. ----------------------------------------------------- */}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <Grid item xs>
                  <Field
                    as={TextField}
                    id="libelle"
                    label={i18next.t("clients.libelleLabel")}
                    control={control}
                    disabled={libelleDisabled}
                    placeholder={i18next.t("clients.libellePlaceHolder")}
                    rules={{
                      required: <Trans>clients.libelleError</Trans>,
                    }}
                    errors={errors}
                  />
                </Grid>
                {mode === EditMode.UPDATE && libelleDisabled && (
                  <Grid
                    item
                    alignItems="centerEditMode.UPDATE"
                    justifyContent="center"
                    sx={{ pt: "32px" }}
                  >
                    <CobaltIcon
                      fontSize="26px"
                      name="edit-3"
                      sx={{ m: "0px", p: "5px" }}
                      onClick={() => setLibelleDisabled(!libelleDisabled)}
                    />
                  </Grid>
                )}
              </Grid>

              {/* Available cientId. ------------------------------------------- */}
              {idsClientChip.length === 0 ? (
                <Divider sx={{ m: "5px 0px 15px 0" }} />
              ) : (
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  sx={{ m: "5px 0px" }}
                >
                  <Grid item>
                    <Typography variant="subtitle2" sx={{ mb: "5px" }}>
                      <Trans>clients.idsClient</Trans>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    {idsClientChip.map((data) => {
                      return (
                        <Grid item key={data}>
                          <Chip
                            label={data}
                            deleteIcon={<CobaltIcon name="x" />}
                            onDelete={handleChipsDelete(data)}
                            sx={{ mb: "5px", mr: "5px" }}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              )}

              {/* Add Client Id. ----------------------------------------------- */}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <Grid item xs>
                  <Field
                    as={TextField}
                    id="extra"
                    label={i18next.t("clients.idsClientAddLabel")}
                    control={control}
                    disabled={addClientIdDisabled}
                    placeholder={i18next.t("clients.idsClientAddPlaceHolder")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CobaltIcon
                            name="plus-circle"
                            aria-label="toggle password visibility"
                            sx={{ cursor: "pointer" }}
                            onClick={handleAddCliendId}
                          />
                        </InputAdornment>
                      ),
                    }}
                    rules={{}}
                    errors={errors}
                  />
                </Grid>
                {mode === EditMode.UPDATE && addClientIdDisabled && (
                  <Grid
                    item
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: "32px" }}
                  >
                    <CobaltIcon
                      fontSize="26px"
                      name="edit-3"
                      sx={{ m: "0px", p: "5px" }}
                      onClick={() =>
                        setAddClientIdDisabled(!addClientIdDisabled)
                      }
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            {/* Action. -------------------------------------------------------- */}
            <DialogActions>
              {/* Cancel. ------------------------------------------------------ */}
              <Button
                onClick={() => {
                  setOpen(false);
                  onEditAction(EditMode.CANCEL, "");
                }}
                color="primary"
                variant="text"
              >
                <Trans>clients.cancel</Trans>
              </Button>

              {/* Save. ---------------------------------------------------------- */}
              <Button type="submit" color="primary">
                <Trans>
                  {mode === EditMode.CREATE
                    ? "clients.Create"
                    : "clients.Update"}
                </Trans>
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}

      {/* Delete confirmation. --------------------------------------------- */}
      {(mode === EditMode.DELETE || mode === EditMode.DELETESELECTED) && (
        <AccountConfirm
          open={open}
          icon="layers"
          title={confirmMessage("Title")}
          message={confirmMessage("Message")}
          button={confirmMessage("Button")}
          onResponse={handleDeleteAction}
        />
      )}
    </>
  );
};
export default ClientEdit;
