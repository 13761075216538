import React from "react";
import { Trans } from "react-i18next";
import { Grid, Paper, Typography } from "@mui/material";

import { FluxStatusIcon } from "app/components/flux/FluxStatusIcon";

import { FluxStatus } from "enums/FluxStatus.d";

// =============================================================================
// FluxDetailBoxId...
// =============================================================================
interface FluxDetailBoxIdProp {
  _key: string;
  date: string;
  status: string;
}
const FluxDetailBoxId: React.FC<FluxDetailBoxIdProp> = ({
  _key,
  date,
  status,
}) => {
  return (
    <Grid item xs={12} sx={{ p: "5px", m: "0px" }}>
      <Paper
        elevation={2}
        sx={{
          height: "100%",
          border: "1px solid",
          borderColor: "cobalt.grey10",
          borderRadius: "8px",
          p: "20px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            xs
          >
            {/* _Key. ------------------------------------------------------ */}
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ mb: "10px" }}
              wrap="nowrap"
            >
              <Grid item>
                <img
                  src="/images/icons/key.svg"
                  style={{
                    height: "18px",
                    marginRight: "10px",
                  }}
                  alt=""
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h3"
                  sx={{ mr: "5px", color: "cobalt.ultramarine" }}
                >
                  <Trans>fluxDetail._key</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Grid
                  item
                  xs
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                  }}
                >
                  <Typography variant="h4" sx={{ color: "cobalt.lynch" }}>
                    {_key}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Date. ------------------------------------------------------ */}
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <img
                  src="/images/icons/date.svg"
                  style={{
                    height: "16px",
                    marginRight: "8px",
                  }}
                  alt=""
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  sx={{ mr: "5px", color: "cobalt.ultramarine" }}
                >
                  <Trans>fluxDetail.lastMilestoneDate</Trans>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" sx={{ color: "cobalt.lynch" }}>
                  {date}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Status. ------------------------------------------------------ */}
          <Grid item xs="auto">
            <Typography
              variant="h3"
              sx={{
                color:
                  status === FluxStatus.ERREUR
                    ? "cobalt.redText"
                    : status === FluxStatus.ANNULE
                    ? "cobalt.lynch"
                    : "cobalt.turquoiseIcon",
              }}
            >
              {FluxStatusIcon(status)}
              <Trans>{status ? "flux." + status : ""}</Trans>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default FluxDetailBoxId;
