import i18next from "i18next";

// =============================================================================
// GetRolesAccording2user...
// =============================================================================
export const GetRolesAccording2user = (userRole: string) => {
  let roles: {}[] = [];
  ["0", "1", "2"].forEach((nu) => {
    if (nu >= userRole) {
      roles.push({ label: i18next.t("userRole." + nu), value: nu });
    }
  });
  return roles;
};
