import i18next from "i18next";

// =============================================================================
// ConvertRole2Value...
// =============================================================================
export const ConvertRole2Value = (role: string) => {
  let value: string = "";
  ["0", "1", "2"].forEach((nu) => {
    if (i18next.t("userRole." + nu) === role) {
      value = nu;
    }
  });
  return value;
};
