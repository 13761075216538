import React from "react";

import { Grid, Paper, Typography } from "@mui/material";
import { CobaltIcon } from "@docaposte-agility/da-design-system";
import { Trans } from "react-i18next";

// =============================================================================
// FluxDetailBox1: display flux detail on box size 2/3.
// =============================================================================
interface FluxDetailBox3Prop {
  id1: string;
  value1: string[];
  id2: string;
  value2: string[];
  onAction: (action: string, value: string) => void;
}
const FluxDetailBox3: React.FC<FluxDetailBox3Prop> = ({
  id1,
  value1,
  id2,
  value2,
  onAction,
}) => {
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    onAction("Select", id);
  };
  var valueFrom;
  if (value1 !== undefined) {
    valueFrom = value1.map((item) => {
      return (
        <Typography
          variant="body1"
          sx={{ mt: "10px", color: "cobalt.lynch", cursor: "pointer" }}
          onClick={(event) => handleClick(event, item)}
        >
          {item}
        </Typography>
      );
    });
  }
  var valueTo;
  if (value2 !== undefined) {
    valueTo = value2.map((item) => {
      return (
        <Typography
          variant="body1"
          sx={{ mt: "10px", color: "cobalt.lynch", cursor: "pointer" }}
          onClick={(event) => handleClick(event, item)}
        >
          {item}
        </Typography>
      );
    });
  }
  return (
    <Grid item xs={12} sm={8} sx={{ p: "5px", m: "0px" }}>
      <Paper
        elevation={2}
        sx={{
          height: "100%",
          border: "1px solid",
          borderColor: "cobalt.grey10",
          borderRadius: "8px",
          p: "20px",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          {/* From. -------------------------- */}
          <Grid item sx={{ width: "calc(50% - 20px)" }}>
            {/* Label. ----------------------------------------------------- */}
            <Typography
              variant="subtitle1"
              sx={{ color: "cobalt.ultramarine" }}
            >
              <img
                src={"/images/icons/" + id1 + ".svg"}
                style={{
                  height: "18px",
                  marginRight: "10px",
                  verticalAlign: "middle",
                }}
                alt=""
              />
              <Trans>{"fluxDetail." + id1}</Trans>
            </Typography>

            {/* Value. ----------------------------------------------------- */}
            <Grid container wrap="nowrap">
              <Grid
                item
                xs
                style={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                {valueFrom}
              </Grid>
            </Grid>
          </Grid>

          {/* Arrow. ------------------------------------------------------- */}
          <Grid item container alignItems="center" sx={{ width: "40px" }}>
            <CobaltIcon
              name="arrow-right"
              fontSize="20px"
              sx={{
                m: "10px",
                color: "cobalt.ultramarine",
              }}
            />
          </Grid>

          {/* To. */}
          <Grid item sx={{ width: "calc(50% - 20px)" }}>
            {/* Label. ----------------------------------------------------- */}
            <Typography
              variant="subtitle1"
              sx={{ color: "cobalt.ultramarine" }}
            >
              <img
                src={"/images/icons/" + id2 + ".svg"}
                style={{
                  height: "18px",
                  marginRight: "10px",
                  verticalAlign: "middle",
                }}
                alt=""
              />
              <Trans>{"fluxDetail." + id2}</Trans>
            </Typography>

            {/* Value. ----------------------------------------------------- */}
            <Grid container wrap="nowrap">
              <Grid
                item
                xs
                style={{
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                {valueTo}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default FluxDetailBox3;
