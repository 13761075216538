import { CobaltIcon } from "@docaposte-agility/da-design-system/";

import { FluxStatus } from "enums/FluxStatus.d";

// =============================================================================
// FluxStatusIcon: display icon according to status.
// =============================================================================
export const FluxStatusIcon = (status: string) => {
  switch (status) {
    case FluxStatus.ERREUR:
      return (
        <CobaltIcon
          name="alert-fill"
          sx={{
            height: "25px",
            color: "cobalt.redText",
            verticalAlign: "text-top",
            m: "0px 10px",
          }}
        />
      );
    case FluxStatus.ANNULE:
      return (
        <CobaltIcon
          name="bullet-point"
          sx={{
            height: "25px",
            color: "cobalt.lynch",
            verticalAlign: "text-top",
            m: "0px 10px",
          }}
        />
      );
    case FluxStatus.FIN_PRODUCTION:
      return (
        <CobaltIcon
          name="check-fill"
          sx={{
            height: "25px",
            color: "cobalt.turquoiseIcon",
            verticalAlign: "text-top",
            m: "0px 10px",
          }}
        />
      );
    default:
      return null;
  }
};
