export const MAX_EXPORT = 1000;

export const ExportCsv = (data) => {
  const headers = "Statut,Date,Cle,Racine,Reception,Type,BU,Appli\n";
  const csv = data
    .map((row) => {
      return Object.values(row).join(",");
    })
    .join("\n");
  return headers + csv;
};
