import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// DeleteUser...
// =============================================================================
export const DeleteUser = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    { id: string }
  > = {},
) => {
  return useMutation(({ id }) => Axios.delete("/user/" + id, { params: {} }), {
    ...config,
  });
};
