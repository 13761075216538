import React from "react";
import { useNavigate } from "react-router-dom";

import { GetFluxList } from "services/flux/GetFluxList";

import { PhidContext } from "utils/Contexts";
import FluxTable from "app/components/flux/FluxTable";
import {
  FluxTableData,
  FluxTableHeadCell,
  FluxPageRowsPageList,
} from "types/FluxTable.d";

import { getStorageValue } from "utils/MemoryStorage";
import { AddHourInFilter } from "../../../utils/FilterUtils";

import i18next from "i18next";

// =============================================================================
// FluxList...
// =============================================================================
let count: number = FluxPageRowsPageList[0];
let offset: number = 0;
const FluxList: React.FC = () => {
  // Members. ------------------------------------------------------------------
  const { fluxFilter, setContextValue, setOffsetGeneral, setCountGeneral } =
    React.useContext(PhidContext);
  const [rowData, setRowData] = React.useState<FluxTableData[]>([]);
  const [totalRows, setTotalRows] = React.useState(0);
  let navigate = useNavigate();

  // Table cell header. --------------------------------------------------------
  const headCells: FluxTableHeadCell[] = [
    {
      id: "col1",
      label: i18next.t("flux.headerStatus"),
      numeric: false,
      disablePadding: true,
      width: "12%",
    },
    {
      id: "col2",
      label: i18next.t("flux.date"),
      numeric: false,
      disablePadding: true,
      width: "11%",
    },
    {
      id: "col3",
      label: i18next.t("flux.headerKey"),
      numeric: false,
      disablePadding: false,
      width: "15%",
    },
    {
      id: "col4",
      label: i18next.t("flux.headerRacine"),
      numeric: false,
      disablePadding: false,
      width: "15%",
    },
    {
      id: "col5",
      label: i18next.t("flux.headerInfoReception"),
      numeric: false,
      disablePadding: false,
      width: "13%",
    },
    {
      id: "col6",
      label: i18next.t("flux.headerType"),
      numeric: false,
      disablePadding: false,
      width: "9%",
    },
    {
      id: "col7",
      label: i18next.t("flux.headerCodeClientBU"),
      numeric: false,
      disablePadding: false,
      width: "13%",
    },
    {
      id: "col8",
      label: i18next.t("flux.headerCodeAppli"),
      numeric: false,
      disablePadding: false,
      width: "12%",
    },
  ];

  // Apply filter. -------------------------------------------------------------
  React.useEffect(() => {
    offset = 0;
    const countOffset = { count, offset };
    const fluxFilterWithHour = AddHourInFilter(fluxFilter);
    const filter = {
      ...JSON.parse(getStorageValue("fluxFilter") || "{}"),
      ...fluxFilterWithHour,
      ...countOffset,
    };
    setOffsetGeneral(0);

    setContextValue("backdrop", true);
    getFluxList(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fluxFilter]);

  // Retrieve flux list fron backend. ----------------------------------------
  const { mutate: getFluxList } = GetFluxList({
    onSuccess: (data) => {
      const response = data["data"];
      const NewRowData = response.results.map((item) => ({
        noSort: "",
        id: item._key,
        col1: item.status || "",
        col2: item.date ? item.date.replace(/T/, " ").replace(/\..+/, "") : "",
        col3: item._keyModified ? item._keyModified : item._key || "",
        col4: item.racine || "",
        col5: item.infoReception || "",
        col6: item.type || "",
        col7: item.codeClientBU || "",
        col8: item.codeAppli || "",
      }));
      setRowData(NewRowData);
      setTotalRows(response.total);
      setContextValue("backdrop", false);
    },
    onError: (error) => {
      setRowData([]);
      setTotalRows(0);
      setContextValue("backdrop", false);
    },
  });

  // handleAction. -------------------------------------------------------------
  const handleAction = (action: string, value: string) => {
    // Select row to view. -----------------------------------------------------
    if (action === "Select") {
      navigate("/phid/flux/" + value);
    }
    // Change page. ------------------------------------------------------------
    else if (action === "ChangePage") {
      offset = +value * count;
      setOffsetGeneral(offset); // Update offsetGeneral immediately
      setCountGeneral(count);
      const countOffset = { count, offset };
      const fluxFilterWithHour = AddHourInFilter(fluxFilter);
      const filter = { ...fluxFilterWithHour, ...countOffset };
      setContextValue("backdrop", true);
      getFluxList(filter);
    }

    // Change number of rows per page. -----------------------------------------
    else if (action === "ChangeRowsPerPage") {
      offset = 0;
      count = +value;
      const countOffset = { count, offset };
      const fluxFilterWithHour = AddHourInFilter(fluxFilter);
      const filter = { ...fluxFilterWithHour, ...countOffset };
      setContextValue("backdrop", true);
      getFluxList(filter);
      setOffsetGeneral(offset);
    }
  };

  // List flux table render. ---------------------------------------------------
  return (
    <FluxTable
      rowData={rowData}
      headCells={headCells}
      totalRows={totalRows}
      onAction={handleAction}
    />
  );
};
export default FluxList;
