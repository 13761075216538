export interface AccountsTableData {
  id: string;
  col1: string;
  col2: string;
  col3: string;
  extra: string;
}
export interface AccountsTableHeadCell {
  id: keyof AccountsTableData;
  label: string;
  disablePadding: boolean;
  numeric: boolean;
}

export type AccountsTableOrder = "asc" | "desc";

export const AccountsPageRowsPageList = [5, 10, 25, 50];
