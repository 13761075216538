import React from "react";
import { MenuItem, Select as MuiSelect, Typography } from "@mui/material";

interface SelectFieldProps {
  label: string;
  menuItems?: Array<{
    label: React.ReactNode;
    value: string;
  }>;
  [x: string]: any;
}

const SelectField = (props: SelectFieldProps) => {
  const {
    label = "",
    value = "",
    placeholder = "",
    helperText = "",
    menuItems = [],
    ...other
  } = props;
  return (
    <>
      <Typography variant="subtitle2" sx={{ mb: "8px" }}>
        {label}
      </Typography>
      <MuiSelect
        fullWidth
        value={value !== "" ? value : "-1"}
        sx={{ color: value === "" ? "cobalt.grey30" : "" }}
        {...other}
      >
        <MenuItem value="-1" disabled>
          {placeholder}
        </MenuItem>
        {menuItems &&
          menuItems.map((menuItem) => (
            <MenuItem key={menuItem.value} value={menuItem.value}>
              {menuItem.label}
            </MenuItem>
          ))}
      </MuiSelect>
      <Typography
        variant="body3"
        sx={{
          fontStyle: "italic",
          color: "cobalt.redText",
        }}
      >
        {helperText}
      </Typography>
    </>
  );
};
export default SelectField;
