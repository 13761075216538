import React from "react";
import { Control, Controller } from "react-hook-form";

interface FieldProps {
  as: React.ElementType<any>;
  id: string;
  control: Control<any, object>;
  errors?: any;
  rules?: any;
  [x: string]: any;
}

// =============================================================================
// Field...
// =============================================================================
const Field = React.forwardRef((props: FieldProps, ref) => {
  const { as: Input, id, control, errors = null, rules = {}, ...other } = props;

  const createHelpers = () => ({
    error: !!errors[id],
    formhelpertextprops: {
      error: !!errors[id],
      variant: "standard",
    },
    helperText: !errors[id] ? "\u00a0" : errors[id]?.message,
  });

  return (
    <Controller
      control={control}
      name={id}
      rules={rules}
      render={({ field }) => (
        <Input
          id={id}
          {...field}
          ref={ref}
          {...(errors ? createHelpers() : {})}
          {...other}
        />
      )}
    />
  );
});
export default Field;
