import React from "react";

import { useForm } from "react-hook-form";
import { Trans } from "react-i18next";
import i18next from "i18next";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Dialog } from "@docaposte-agility/da-design-system";
import { CobaltIcon } from "@docaposte-agility/da-design-system/";

import AccountConfirm from "app/components/accounts/AccountConfirm";
import Field from "app/components/formField/Field";
import TextField from "app/components/formField/TextField";
import PasswordField from "app/components/formField/PasswordField";
import SelectField from "app/components/formField/SelectField";

import DateFormat from "services/date/DateFormat";
import { PostUser } from "services/users/PostUser";
import { PatchUser } from "services/users/PatchUser";
import { DeleteUser } from "services/users/DeleteUser";

import { getStorageValue } from "utils/MemoryStorage";
import { PhidContext } from "utils/Contexts";
import { ConvertRole2Value } from "utils/ConvertRole2Value";
import { GetRolesAccording2user } from "utils/GetRolesAccording2user";

import { Account } from "types/Account.d";
import { AccountsTableData } from "types/AccountsTable.d";
import { UserForm } from "types/UserForm.d";
import { UserBoPost } from "types/UserBoPost.d";
import { UserBoPatch } from "types/UserBoPatch.d";
import { EditMode } from "enums/EditMode.d";

let toBeDeleted: number = 0;
let deleteOK: string[] = [];
let deleteWIP: string[] = [];

// =============================================================================
// UserEdit...
// =============================================================================
interface UserEditProp {
  mode: EditMode;
  userForm: UserForm;
  selected: string[];
  onEditAction: (mode: EditMode, status: string, account?: Account) => void;
}
const UserEdit: React.FC<UserEditProp> = ({
  mode,
  userForm,
  selected,
  onEditAction,
}) => {
  // Members. ------------------------------------------------------------------
  const { setContextValue } = React.useContext(PhidContext);
  const [open, setOpen] = React.useState(true);
  const [identifiantDisabled, setIdentifiantDisabled] = React.useState(false);
  const [passwordDisabled, setPasswordDisabled] = React.useState(false);
  const [availableRoles] = React.useState(
    GetRolesAccording2user(getStorageValue("role") || "2"),
  );
  const [rowUser, setRowUser] = React.useState<AccountsTableData>({
    id: "",
    col1: "",
    col2: "",
    col3: "",
    extra: "",
  });

  // Form control. -------------------------------------------------------------
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<UserForm>({
    defaultValues: {
      identifiant: "",
      password: "",
      role: "",
    },
  });

  // Init formular on mode or client input changes. ----------------------------
  React.useEffect(() => {
    setOpen(true);
    setValue("identifiant", userForm.identifiant);
    setValue("password", userForm.password);
    setValue("role", userForm.role);
    setIdentifiantDisabled(mode !== EditMode.CREATE);
    setPasswordDisabled(mode !== EditMode.CREATE);
    setRowUser({
      id: userForm._key,
      col1: userForm.identifiant,
      col2: DateFormat(userForm.created_at, i18next.t("common.date")),
      col3: i18next.t("userRole." + userForm.role),
      extra: userForm.password,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, userForm, selected]);

  // handlers. -----------------------------------------------------------------
  const handleSave = (userForm: UserForm) => {
    // Create. -----------------------------------------------------------------
    if (mode === EditMode.CREATE) {
      setContextValue("backdrop", true);
      let userBo: UserBoPost = {
        login: userForm?.identifiant,
        password: userForm?.password,
        created_by: getStorageValue("id") || "",
        role: Number(userForm?.role),
      };
      createUser(userBo);
    }

    // Update. -----------------------------------------------------------------
    else if (mode === EditMode.UPDATE) {
      setContextValue("backdrop", true);
      let userBo: UserBoPatch = {
        _key: rowUser.id,
      };
      if (userForm?.identifiant !== rowUser.col1) {
        userBo["login"] = userForm?.identifiant;
      }
      if (userForm?.password !== rowUser.extra) {
        userBo["password"] = userForm?.password;
      }
      if (userForm?.role !== rowUser.col3) {
        userBo["role"] = Number(userForm?.role);
      }
      if (Object.keys(userBo).length > 1) {
        updateUser(userBo);
      } else {
        onEditAction(EditMode.CANCEL, "");
      }
    }
    setOpen(false);
  };
  const handleDeleteAction = (confirmed: boolean) => {
    if (confirmed) {
      setContextValue("backdrop", true);
      deleteOK = [];
      deleteWIP = [];

      // Delete. ---------------------------------------------------------------
      if (mode === EditMode.DELETE) {
        if (userForm._key !== getStorageValue("id")) {
          toBeDeleted = 1;
          deleteWIP.push(userForm._key);
          deleteUser({ id: userForm._key });
        } else {
          toBeDeleted = 0;
          onEditAction(mode, "WARNING", { selected: deleteOK });
        }
      }

      // Delete selected. ------------------------------------------------------
      else if (mode === EditMode.DELETESELECTED) {
        deleteWIP = selected;
        toBeDeleted = deleteWIP.length;
        selected.forEach((elemId) => {
          if (elemId !== getStorageValue("id")) {
            deleteUser({ id: elemId });
          } else {
            toBeDeleted = toBeDeleted - 1;
            if (toBeDeleted === 0) {
              onEditAction(mode, "ERROR", { selected: deleteOK });
            }
          }
        });
      }
    } else {
      onEditAction(EditMode.CANCEL, "");
    }
    setOpen(false);
  };

  // BackOffice response. ------------------------------------------------------
  const { mutate: createUser } = PostUser({
    onSuccess: (data) => {
      const resData = data["data"];
      const addedUser: Account = {
        user: {
          _key: resData._key,
          login: resData.login,
          password: resData.password,
          created_at: resData.created_at,
          created_by: resData.created_by,
          role: resData.role,
        },
      };
      onEditAction(mode, "OK", addedUser);
    },
    onError: (error) => {
      onEditAction(mode, "ERROR");
    },
  });
  const { mutate: updateUser } = PatchUser({
    onSuccess: (data) => {
      const resConfig = data["config"];
      const param = JSON.parse(resConfig.data);
      const UpdatedUser: Account = {
        user: {
          _key: rowUser.id,
          login: param.login !== undefined ? param.login : rowUser.col1,
          password:
            param.password !== undefined ? param.password : rowUser.extra,
          created_at: rowUser.col2,
          created_by: "",
          role: param.role !== undefined ? param.role : rowUser.col3,
        },
      };
      onEditAction(mode, "OK", UpdatedUser);
    },
    onError: (error) => {
      const UpdatedUser: Account = {
        user: {
          _key: rowUser.id,
          login: rowUser.col1,
          password: rowUser.extra,
          created_at: rowUser.col2,
          created_by: "",
          role: Number(ConvertRole2Value(rowUser.col3)),
        },
      };
      onEditAction(mode, "ERROR", UpdatedUser);
    },
  });
  const { mutate: deleteUser } = DeleteUser({
    onSuccess: (data) => {
      const resConfig = data["config"];
      const index = resConfig.url.replace("/user/", "");
      deleteOK.push(index);
      toBeDeleted = toBeDeleted - 1;
      deleteWIP = deleteWIP.filter((row) => row !== index);
      if (toBeDeleted === 0) {
        if (deleteWIP.length !== 0) {
          onEditAction(mode, "ERROR", { selected: deleteOK });
        } else {
          onEditAction(mode, "OK", { selected: deleteOK });
        }
      }
    },
    onError: (error) => {
      toBeDeleted = toBeDeleted - 1;
      if (toBeDeleted === 0) {
        onEditAction(mode, "ERROR", { selected: deleteOK });
      }
    },
  });

  // Confirm messages. ---------------------------------------------------------
  const confirmMessage = (msgType: string) => {
    if (msgType === "Title") {
      return i18next.t("confirm.deleteTitle");
    } else if (msgType === "Message") {
      if (mode === EditMode.DELETE) {
        return i18next
          .t("confirm.deleteMessage")
          .replace("__USER__", '"' + userForm.identifiant + '"');
      } else {
        return i18next
          .t("confirm.deleteMessage")
          .replace(
            "__USER__",
            selected.length +
              i18next.t("confirm.users") +
              (selected.length > 1 ? "s" : ""),
          );
      }
    } else if (msgType === "Button") {
      if (mode === EditMode.DELETE) {
        return (
          i18next.t("confirm.deleteButton").replace("__USER__", "1") +
          i18next.t("confirm.users")
        );
      } else {
        return (
          i18next
            .t("confirm.deleteButton")
            .replace("__USER__", "" + selected.length) +
          i18next.t("confirm.users") +
          (selected.length > 1 ? "s" : "")
        );
      }
    } else {
      return "__" + msgType + "__";
    }
  };

  // User form render. ---------------------------------------------------------
  return (
    <>
      {/* Edit formular. --------------------------------------------------- */}
      {(mode === EditMode.CREATE || mode === EditMode.UPDATE) && (
        <Dialog
          open={open}
          maxWidth="sm"
          fullWidth={true}
          PaperProps={{
            sx: {
              minHeight: "425px",
            },
          }}
          onClose={() => {
            setOpen(false);
            onEditAction(EditMode.CANCEL, "");
          }}
        >
          {/* Title. ----------------------------------------------------------- */}
          <DialogTitle>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <CobaltIcon
                  fontSize="26px"
                  name="users"
                  sx={{ m: "0px", pr: "10px" }}
                />
              </Grid>
              <Grid item>
                <Typography variant="h3" component="h4">
                  <Trans>
                    {mode === EditMode.CREATE
                      ? "users.createTitle"
                      : "users.updateTitle"}
                  </Trans>
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>

          {/* Formular. -------------------------------------------------------- */}
          <form onSubmit={handleSubmit(handleSave)}>
            <DialogContent dividers sx={{ p: "5px 32px" }}>
              {/* Identifiant. ------------------------------------------------- */}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <Grid item xs>
                  <Field
                    as={TextField}
                    id="identifiant"
                    label={i18next.t("users.identLabel")}
                    control={control}
                    disabled={identifiantDisabled}
                    placeholder={i18next.t("users.identPlaceHolder")}
                    rules={{
                      required: <Trans>users.identError</Trans>,
                    }}
                    errors={errors}
                  />
                </Grid>
                {mode === EditMode.UPDATE && identifiantDisabled && (
                  <Grid
                    item
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: "32px" }}
                  >
                    <CobaltIcon
                      fontSize="26px"
                      name="edit-3"
                      sx={{ m: "0px", p: "5px" }}
                      onClick={() =>
                        setIdentifiantDisabled(!identifiantDisabled)
                      }
                    />
                  </Grid>
                )}
              </Grid>

              {/* Mot de passe. ------------------------------------------------ */}
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <Grid item xs>
                  <Field
                    as={PasswordField}
                    id="password"
                    label={i18next.t("users.passwordLabel")}
                    control={control}
                    disabled={passwordDisabled}
                    placeholder={i18next.t("users.passwordPlaceHolder")}
                    rules={{
                      required: <Trans>users.passwordError</Trans>,
                    }}
                    errors={errors}
                  />
                </Grid>
                {mode === EditMode.UPDATE && passwordDisabled && (
                  <Grid
                    item
                    alignItems="center"
                    justifyContent="center"
                    sx={{ pt: "32px" }}
                  >
                    <CobaltIcon
                      fontSize="26px"
                      name="edit-3"
                      sx={{ m: "0px", p: "5px" }}
                      onClick={() => setPasswordDisabled(!passwordDisabled)}
                    />
                  </Grid>
                )}
              </Grid>

              {/* Role. --------------------------------------------------------*/}
              <Field
                as={SelectField}
                id="role"
                label={i18next.t("users.roleLabel")}
                control={control}
                disabled={false}
                placeholder={i18next.t("users.rolePlaceHolder")}
                menuItems={availableRoles}
                rules={{
                  required: <Trans>users.roleError</Trans>,
                }}
                errors={errors}
              />
            </DialogContent>

            {/* Action. -------------------------------------------------------- */}
            <DialogActions>
              {/* Cancel. ------------------------------------------------------ */}
              <Button
                onClick={() => {
                  setOpen(false);
                  onEditAction(EditMode.CANCEL, "");
                }}
                color="primary"
                variant="text"
              >
                <Trans>users.cancel</Trans>
              </Button>

              {/* Save. -------------------------------------------------------- */}
              <Button type="submit" color="primary">
                <Trans>
                  {mode === EditMode.CREATE ? "users.Create" : "users.Update"}
                </Trans>
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}

      {/* Delete confirmation. --------------------------------------------- */}
      {(mode === EditMode.DELETE || mode === EditMode.DELETESELECTED) && (
        <AccountConfirm
          open={open}
          icon="users"
          title={confirmMessage("Title")}
          message={confirmMessage("Message")}
          button={confirmMessage("Button")}
          onResponse={handleDeleteAction}
        />
      )}
    </>
  );
};
export default UserEdit;
