import * as React from "react";
import i18next from "i18next";

import packageJson from "../../package.json";

import { GetVersion } from "services/version/GetVersion";

import { getStorageValue } from "utils/MemoryStorage";

import { UserRoles } from "enums/UserRoles";
import { FluxPageRowsPageList } from "types/FluxTable.d";

// exported Phid context. ------------------------------------------------------
export const PhidContext = React.createContext({
  drawer: true,
  navRoutes: {},
  mode: "",
  toasterSuccess: "",
  toasterError: "",
  toasterWarning: "",
  backdrop: false,
  fluxFilter: {},
  dashboardFilter: {},
  clientFilter: {},
  setContextValue: (key: string, value?: string | number | boolean) => null,
  resetContext: () => null,
  countGeneral: FluxPageRowsPageList[0],
  offsetGeneral: 0,
  setCountGeneral: (countGeneral) => {},
  setOffsetGeneral: (offsetGeneral) => {},
});

// =============================================================================
// Contexts...
// =============================================================================
interface ContextsProps {
  children: React.ReactNode;
}
const Contexts: React.FC<ContextsProps> = ({ children }) => {
  // Members. ------------------------------------------------------------------
  const [drawer, setDrawer] = React.useState(true);
  const [navRoutes, setNavRoutes] = React.useState({});
  const [mode, setMode] = React.useState("");
  const [toasterSuccess, setToasterSuccess] = React.useState("");
  const [toasterError, setToasterError] = React.useState("");
  const [toasterWarning, setToasterWarning] = React.useState("");
  const [backdrop, setBackdrop] = React.useState(false);
  const [fluxFilter, setFluxFilter] = React.useState({});
  const [dashboardFilter, setDashboardFilter] = React.useState({});
  const [clientFilter, setClientFilter] = React.useState({});

  const [countGeneral, setCountGeneral] = React.useState(
    FluxPageRowsPageList[0],
  );
  const [offsetGeneral, setOffsetGeneral] = React.useState(0);
  // Init PhidContext. ---------------------------------------------------------
  React.useEffect(() => {
    // Display font version. ---------------------------------------------------
    getBoVersion({});
    let mode = "";
    if (process.env.NODE_ENV === "development") {
      mode = ".dev (" + process.env.REACT_APP_BO_MODE + ")";
    } else {
      console.debug = (...args) => {};
    }
    console.log(
      "[PHID] Starting " + packageJson.name + " V" + packageJson.version + mode,
    );
    console.log(
      '[PHID]  boBaseUrl: "' + process.env.REACT_APP_BO_BASE_URL + '"',
    );

    // localstorage used by axios. ---------------------------------------------
    sessionStorage.setItem("baseURL", process.env.REACT_APP_BO_BASE_URL || "");
    if (process.env.REACT_APP_BO_MODE === "simul") {
      sessionStorage.removeItem("token");
    }

    // Reset context. ----------------------------------------------------------
    resetContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Retrieve bo version. ------------------------------------------------------
  const { mutate: getBoVersion } = GetVersion({
    onSuccess: (data) => {
      const res = data["data"];
      console.log("[PHID]  boVersion: V" + res);
    },
    onError: (error) => {
      console.log("[PHID]  boVersion: V?.?.?");
    },
  });

  // resetContext. -------------------------------------------------------------
  const resetContext = () => {
    // set hook context. -------------------------------------------------------
    setMode(process.env.REACT_APP_BO_MODE || "");
    setDrawer(true);
    setToasterSuccess("");
    setToasterWarning("");
    setToasterError("");
    setBackdrop(false);
    setContextValue(
      "userRoutes",
      getStorageValue("role") || UserRoles.NOT_DEFINE,
    );
    setFluxFilter({});
    setDashboardFilter({});
    setClientFilter({});

    return null;
  };

  // setContextValue. ----------------------------------------------------------
  const setContextValue = (key: string, value?: string | number | boolean) => {
    switch (key) {
      // Context variables. ----------------------------------------------------
      case "drawer": {
        if (value) {
          setDrawer(Boolean(value));
        } else {
          setDrawer(!drawer);
        }
        break;
      }
      case "mode": {
        setMode(String(value));
        break;
      }
      case "toasterSuccess": {
        setToasterSuccess(String(value));
        break;
      }
      case "toasterWarning": {
        setToasterWarning(String(value));
        break;
      }
      case "toasterError": {
        setToasterError(String(value));
        break;
      }
      case "backdrop": {
        setBackdrop(Boolean(value));
        break;
      }
      case "fluxFilter": {
        setFluxFilter(JSON.parse(String(value)));
        break;
      }
      case "dashboardFilter": {
        setDashboardFilter(JSON.parse(String(value)));
        break;
      }
      case "clientFilter": {
        setClientFilter(JSON.parse(String(value)));
        break;
      }
      case "userRoutes": {
        if (Number(value) === UserRoles.SUPER_ADMIN) {
          setNavRoutes({
            dashboard: ["grid", i18next.t("drawer.dashboard")],
            flux: ["clipboard", i18next.t("drawer.flux")],
            clients: ["layers", i18next.t("drawer.clients")],
            users: ["users", i18next.t("drawer.users")],
          });
        } else if (Number(value) === UserRoles.ADMIN) {
          setNavRoutes({
            dashboard: ["grid", i18next.t("drawer.dashboard")],
            flux: ["clipboard", i18next.t("drawer.flux")],
            users: ["users", i18next.t("drawer.users")],
          });
        } else if (Number(value) === UserRoles.USER) {
          setNavRoutes({
            dashboard: ["grid", i18next.t("drawer.dashboard")],
            flux: ["clipboard", i18next.t("drawer.flux")],
          });
        } else {
          setNavRoutes({});
        }
        break;
      }
    }
    return null;
  };

  // save context into sessionStorage only in dev mode. ------------------------
  React.useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      sessionStorage.setItem(
        "context",
        JSON.stringify({
          drawer,
          navRoutes,
          mode,
          toasterSuccess,
          toasterWarning,
          toasterError,
          backdrop,
          fluxFilter,
          dashboardFilter,
          clientFilter,
        }),
      );
    }
  }, [
    drawer,
    navRoutes,
    mode,
    toasterSuccess,
    toasterWarning,
    toasterError,
    backdrop,
    fluxFilter,
    dashboardFilter,
    clientFilter,
  ]);

  // Provider. -----------------------------------------------------------------
  return (
    <PhidContext.Provider
      value={{
        drawer,
        navRoutes,
        mode,
        toasterSuccess,
        toasterWarning,
        toasterError,
        backdrop,
        fluxFilter,
        dashboardFilter,
        clientFilter,
        setContextValue,
        resetContext,
        countGeneral,
        offsetGeneral,
        setCountGeneral,
        setOffsetGeneral,
      }}
    >
      {children}
    </PhidContext.Provider>
  );
};

export default Contexts;
