import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { Grid, Typography } from "@mui/material";

import { CobaltIcon } from "@docaposte-agility/da-design-system/";

import { PhidContext } from "utils/Contexts";
import FluxDetailBoxId from "app/components/flux/FluxDetailBoxId";
import FluxDetailBox1 from "app/components/flux/FluxDetailBox1";
import FluxDetailBox2 from "app/components/flux/FluxDetailBox2";
import FluxDetailBox3 from "app/components/flux/FluxDetailBox3";
import FluxDetailBoxMore1 from "app/components/flux/FluxDetailBoxMore1";
import { FluxStatusIcon } from "app/components/flux/FluxStatusIcon";

import { GetFluxDetail } from "services/flux/GetFluxDetail";

// =============================================================================
// FluxDetail...
// =============================================================================
interface FluxDetailProp {
  id: string;
}
const FluxDetail: React.FC<FluxDetailProp> = ({ id }) => {
  // Members. ------------------------------------------------------------------
  const { setContextValue } = React.useContext(PhidContext);
  const [fluxDetail, setFluxDetail] = React.useState({});
  let navigate = useNavigate();

  // Load flux detail.. --------------------------------------------------------
  React.useEffect(() => {
    setContextValue("backdrop", true);
    getFluxDetail({ id: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // BackOffice response. ------------------------------------------------------
  const { mutate: getFluxDetail } = GetFluxDetail({
    onSuccess: (data) => {
      const resData = data["data"];
      setFluxDetail(resData);
      setContextValue("backdrop", false);
    },
    onError: (error) => {
      setFluxDetail({});
      setContextValue("backdrop", false);
    },
  });

  // handleAction. -------------------------------------------------------------
  const handleAction = (action: string, value: string) => {
    // Select row to view. -----------------------------------------------------
    if (action === "Select") {
      navigate("/phid/flux/" + value);
      getFluxDetail({ id: value });
    }
  };

  // List client render. -------------------------------------------------------
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      {/* Back. ------------------------------------------------------------ */}
      <Grid item sx={{ m: "20px 0px 0px 10px" }}>
        <Link to="/phid/flux" style={{ textDecoration: "none" }}>
          <Typography variant="h4">
            <CobaltIcon
              name="chevron-left"
              sx={{
                height: "16px",
                color: "cobalt.ultramarine",
                verticalAlign: "middle",
              }}
            />
            <Trans>fluxDetail.back</Trans>
          </Typography>
        </Link>
      </Grid>

      {/* Title. ----------------------------------------------------------- */}
      <Grid item>
        <Typography
          variant="h1"
          sx={{
            p: "22px 5px 34px 10px",
            color: "cobalt.ultramarine",
          }}
        >
          <Trans>fluxDetail.title</Trans>
          {FluxStatusIcon(fluxDetail["status"])}
        </Typography>
      </Grid>

      {/* Detail. ---------------------------------------------------------- */}
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ p: "0", m: "0" }}
      >
        <FluxDetailBoxId
          _key={fluxDetail["_key"]}
          date={fluxDetail["date"]}
          status={fluxDetail["status"]}
        />
        <FluxDetailBox3
          id1="from"
          value1={fluxDetail["_From"]}
          id2="to"
          value2={fluxDetail["_To"]}
          onAction={handleAction}
        />
        <FluxDetailBox1 id="racine" value={fluxDetail["racine"]} />
        <FluxDetailBox2
          id1="dateFrom"
          value1={fluxDetail["dateEmission"]}
          id2="dateTo"
          value2={fluxDetail["dateReception"]}
        />
        <FluxDetailBox1 id="bu" value={fluxDetail["bu"]} />
        <FluxDetailBox2
          id1="infoFrom"
          value1={fluxDetail["infoEmission"]}
          id2="infoTo"
          value2={fluxDetail["infoReception"]}
        />
        <FluxDetailBox1 id="destPartner" value={fluxDetail["destPartenaire"]} />
        <FluxDetailBox1 id="fluxIntegres" value={fluxDetail["fluxIntegres"]} />
        <FluxDetailBox1 id="fluxGeneres" value={fluxDetail["fluxGeneres"]} />
        <FluxDetailBox1
          id="codeClientBU"
          value={fluxDetail["codeClientBU_orig"]}
        />
        <FluxDetailBox1 id="codeAppli" value={fluxDetail["codeAppli"]} />
        <FluxDetailBox1
          id="volumes"
          value={
            fluxDetail["volumes"]
              ? JSON.stringify(fluxDetail["volumes"], null, 2)
              : ". . ."
          }
        />
        <FluxDetailBoxMore1
          id="operations"
          value={JSON.stringify(fluxDetail["operations"], null, 2)}
        />
        <FluxDetailBoxMore1
          id="fluxInfoIdentifications"
          value={JSON.stringify(fluxDetail["FluxInfoIdentifications"], null, 2)}
        />
        <FluxDetailBoxMore1
          id="evenements"
          value={JSON.stringify(fluxDetail["evenements"], null, 2)}
        />
      </Grid>
    </Grid>
  );
};
export default FluxDetail;
