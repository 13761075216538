import Axios, { AxiosError } from "axios";
import { UseMutationOptions, useMutation } from "react-query";

// =============================================================================
// GetFluxDetail...
// =============================================================================
export const GetFluxDetail = (
  config: UseMutationOptions<
    { id_token: string },
    AxiosError,
    {
      id: string;
    }
  > = {},
) => {
  return useMutation(({ id }) => Axios.get("/flux/" + id, { params: {} }), {
    ...config,
  });
};
